
import Vue from "vue";
import { DateTime } from "luxon";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import ReportService from "../../../../api/report-service";

export default Vue.extend({
    props: {
        report: Object,
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data(): {
        isSaving: boolean;
        isSavingDraft: boolean;
        form: {
            title: string;
            reference: string;
            reportingFrom: string;
            reportingTo: string;
            sectorialRegulator: string;
            otherRegulators: string[];
        };
        sectorialRegulatorOptions: string[];
        otherRegulatorsOptions: string[];
        minDate: any;
    } {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(today);
        return {
            isSaving: false,
            isSavingDraft: false,
            minDate,
            form: {
                title: "",
                reference: "",
                reportingFrom: "",
                reportingTo: "",
                sectorialRegulator: "",
                otherRegulators: [],
            },
            sectorialRegulatorOptions: [
                "Corporate Affairs Commission(CAC)",
                // "The Financial Reporting Council of Nigeria (FRC)",
                "National Insurance Commission(NAICOM)",
                "National Pension Commission (PENCOM)",

                "Securities and Exchange Commission (SEC)",

                // "Nigeria Stock Exchange (NSE)",

                "Central Bank of Nigeria (CBN)",

                "Standards Organisation of Nigeria (SON)",

                "National Agency for Food and Drug Administration and Control (NAFDAC)",

                "Department of Petroleum Resources (DPR)",

                "National Office of Technology Acquisition and Promotion (NOTAP) ",

                "The National Investment Promotion Commission (NIPC) ",

                "Nigeria Deposit Insurance Corporation (NDIC)",

                "Nigeria Communications Commission (NCC)",

                "Federal Inland Revenue Service (FIRS)",

                "Federal Aviation Authority Of Nigeria (FAAN)",

                "Bureau of Public Enterprises (BPE)",

                "Federal Competition and Consumer Protection Commission (FCCPC)",

                "Nigeria LNG Limited (NLNG)",

                "Nigeria Electricity Regulatory Commission (NERC)",
            ],
            otherRegulatorsOptions: [
                "Corporate Affairs Commission(CAC)",
                // "The Financial Reporting Council of Nigeria (FRC)",
                "National Insurance Commission(NAICOM)",
                "National Pension Commission (PENCOM)",

                "Securities and Exchange Commission (SEC)",

                // "Nigeria Stock Exchange (NSE)",

                "Central Bank of Nigeria (CBN)",

                "Standards Organisation of Nigeria (SON)",

                "National Agency for Food and Drug Administration and Control (NAFDAC)",

                "Department of Petroleum Resources (DPR)",

                "National Office of Technology Acquisition and Promotion (NOTAP) ",

                "The National Investment Promotion Commission (NIPC) ",

                "Nigeria Deposit Insurance Corporation (NDIC)",

                "Nigeria Communications Commission (NCC)",

                "Federal Inland Revenue Service (FIRS)",

                "Federal Aviation Authority Of Nigeria (FAAN)",

                "Bureau of Public Enterprises (BPE)",

                "Federal Competition and Consumer Protection Commission (FCCPC)",

                "Nigeria LNG Limited (NLNG)",

                "Nigeria Electricity Regulatory Commission (NERC)",
            ],
        };
    },
    computed: {
        ...mapState("reports", ["existingReports"]),
    },
    methods: {
        ...mapActions("reports", ["setDetailsData"]),
        async save(isLater = false) {
            if (isLater) this.isSavingDraft = true;
            else this.isSaving = true;

            const { id } = this.$route.query;

            const body = {
                reportTitle: this.form.title,
                reportingTimeLineFrom: this.form.reportingFrom,
                reportingTimeLineTo: this.form.reportingTo,
                sectorialRegulator: this.form.sectorialRegulator,
                otherRegulator: this.form.otherRegulators,
            };
            try {
                const res = await ReportService.updateReportDetails({ id: id as string, body });
                this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
                if (!isLater) {
                    this.$emit("completed");
                }
                this.$emit("refresh");
            } catch (error) {
                this.isSaving = false;
                this.isSavingDraft = false;
            } finally {
                this.isSaving = false;
                this.isSavingDraft = false;
            }
        },
        async saveDraft() {
            await this.save(true);
            this.$router.push("/reports");
        },
        cancel() {
            this.$emit("back");
        },
        matchForm(payload: any) {
            // console.log("matched", payload.details);
            this.form.sectorialRegulator = payload.details?.sectorialRegulator;

            this.form.otherRegulators = [...payload?.details?.otherRegulator];
            this.form.title = payload?.details?.reportTitle || "Compliance Status with the Nigerian Code of Corporate Governance-202";
            this.form.reference = payload?.details.reportCode;
            this.form.reportingFrom = DateTime.fromISO(payload?.details?.reportingTimeLineFrom).toISODate() || "";

            this.form.reportingTo = DateTime.fromISO(payload?.details?.reportingTimeLineTo).toISODate() || "";
        },
    },
    async mounted() {
        if (this.report?.id) {
            this.matchForm(this.report);
        }
    },
    watch: {
        report() {
            this.matchForm(this.report);
        },
    },
    validations: {
        form: {
            title: {},
            reference: {},
            reportingFrom: {
                required,
            },
            reportingTo: {
                required,
                minValue(val, { reportingFrom }) {
                    return new Date(val) > new Date(reportingFrom);
                },
            },
            sectorialRegulator: {},
            otherRegulators: {},
        },
    },
});
