
import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import CreateCommitteeModal from "@/app/components/modals/createBoardCommittee.vue";
import ReportService from "@/api/report-service";

enum EStructures {
    "Executive management and Leadership" = "S1",
    "Company contact persons" = "S2",
    "Board of Directors" = "S3",
    "Company Secretary(s)" = "S4",
    "Compliance Officer(s)" = "S5",
    "Investor Relations Officer(s)" = "S6",
}

export default Vue.extend({
    props: {
        report: Object,
    },
    components: {
        CreateUserModal,
        CreateCommitteeModal,
    },
    data() {
        return {
            isSaving: false,
            isLoading: false,
            fetchingUser: false,
            isSavingDraft: false,
            form: {
                committees: [],
                executives: [],
                boardOfDirectors: [],
                companySecretaries: [],
                complianceOfficers: [],
                companyContactPersons: [],
                investorRelationsOfficers: [],
                committeesIds: [],
                executivesIds: [],
                boardOfDirectorsIds: [],
                companySecretariesIds: [],
                complianceOfficersIds: [],
                companyContactPersonsIds: [],
                investorRelationsOfficersIds: [],
            },
            committees: [],
            directorPositionOptions: [
                "Board Chairman",
                "Governance Committee Chairman",
                "Chairman of Committee Responsible for Governance ",
                "Executive Director",
                "Non-Executive",
                "Independent Non-Executive Director",
                "Managing Director",
                "Legal Adviser",
            ],
            defaultPositionOptions: [
                "Board Chairman",
                "Governance Committee Chairman",
                "Chairman of Committee Responsible for Governance ",
                "Legal Adviser",
                "Managing Director",
                "Secretarial Support Staff",
                "Legal Officer",
                "Executive Director",
                "Acting Company Secretary",
                "Legal Support Staff",
                "Company Secretarial Staff",
                "Compliance Officer",
                "Non Executive Director",
                "Company Secretary",
            ],
            isFromModal: false,
            userData: null,
        };
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
        }),
        handleUserEditValue({ modalId, data, ref }: { modalId: string; data: any; ref: string }) {
            console.log("Data gotten from the lkini", data);
            // (this.$refs[ref] as any).fetchUser(data.id).then(() => {
            this.$bvModal.show(`${modalId}`);
            this.userData = data;
            // });
        },
        async save(isLater = false) {
            if (isLater) this.isSavingDraft = true;
            else this.isSaving = true;

            const { id } = this.$route.query;

            const committees: any = [];

            this.form.committees.forEach((committee: any) => {
                const found = this.committees.find((value: any) => value.id === committee.id);
                if (found) {
                    committees.push(found);
                }
            });

            const body = {
                commitee: committees.map((committee: any) => {
                    return {
                        commiteeId: committee.id,
                        userIds: committee.users.map((user: any) => {
                            return user.id;
                        }),
                    };
                }),
                positionUsers: [
                    { positionName: EStructures["Board of Directors"], userIds: this.form.boardOfDirectors.map((value: any) => value.id) },
                    { positionName: EStructures["Executive management and Leadership"], userIds: this.form.executives.map((value: any) => value.id) },
                    { positionName: EStructures["Company contact persons"], userIds: this.form.companyContactPersons.map((value: any) => value.id) },
                    { positionName: EStructures["Company Secretary(s)"], userIds: this.form.companySecretaries.map((value: any) => value.id) },
                    { positionName: EStructures["Compliance Officer(s)"], userIds: this.form.complianceOfficers.map((value: any) => value.id) },
                    { positionName: EStructures["Investor Relations Officer(s)"], userIds: this.form.investorRelationsOfficers.map((value: any) => value.id) },
                ],
            };
            try {
                const res = await ReportService.updateStructure({ id: id as string, body });
                if (!this.isFromModal) {
                    this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
                }
            } catch (error) {
                this.isSaving = false;
                this.isSavingDraft = false;
            } finally {
                if (!isLater) {
                    this.$emit("completed");
                }
                this.$emit("refresh");
                this.isSaving = false;
                this.isSavingDraft = false;
            }
        },
        cancel() {
            this.$emit("back");
        },
        async saveDraft(isButton = false) {
            await this.save(true);
            if (isButton) {
                this.$router.push("/reports");
            }
        },
        handleCommitteeEditValue(val: any) {
            (this.$refs.createCommitteeModal as any).fetchCommittee(val.id).then(() => {
                this.$bvModal.show("modal-create-committee");
            });
        },
        fetchUsersList() {
            try {
                this.fetchingUser = true;
                this.fetchUsers();
                this.fetchingUser = false;
            } catch (error) {
                this.fetchingUser = false;
            }
        },
        async fetchCommittees() {
            const committees = await ReportService.fetchCommittees();
            this.committees = committees.data.map((committee: any) => {
                return { id: committee.id, fullName: committee.name, roles: `${committee.users.length} Members`, email: committee.description, users: committee.users };
            });
        },
        findPosition(value: string) {
            const found = this.report.structure.positionUsers.find((val: any) => val.positionName === value);
            return found.userIds;
        },
        matchForm(payload: any) {
            this.form.committeesIds = payload.structure.commitee;
            this.form.boardOfDirectorsIds = this.findPosition("S3");
            this.form.executivesIds = this.findPosition("S1");
            this.form.companyContactPersonsIds = this.findPosition("S2");
            this.form.companySecretariesIds = this.findPosition("S4");
            this.form.complianceOfficersIds = this.findPosition("S5");
            this.form.investorRelationsOfficersIds = this.findPosition("S6");
        },
        extractPositions(toCompare: any, standard: any) {
            const users: any = [];

            toCompare.forEach((toCompareId: any) => {
                const found = standard.find((value: any) => value.id === toCompareId);
                if (found) {
                    users.push(found);
                }
            });

            return users;
        },
    },
    computed: {
        ...mapState("users", ["users"]),
    },
    watch: {
        report(curr, prev) {
            if (curr?.id && curr !== prev) {
                this.matchForm(curr);
            }
        },
        committees(val) {
            const committees: any = [];

            this.form.committeesIds.forEach((committee: any) => {
                const found = val.find((value: any) => value.id === committee.commiteeId);
                if (found) {
                    committees.push(found);
                }
            });

            this.form.committees = committees;
        },
        users(cur, prev) {
            if (prev.length <= 0 || cur.length > 0) {
                this.form.executives = this.extractPositions(this.form.executivesIds, cur);
                this.form.boardOfDirectors = this.extractPositions(this.form.boardOfDirectorsIds, cur);
                this.form.companySecretaries = this.extractPositions(this.form.companySecretariesIds, cur);
                this.form.complianceOfficers = this.extractPositions(this.form.complianceOfficersIds, cur);
                this.form.companyContactPersons = this.extractPositions(this.form.companyContactPersonsIds, cur);
                this.form.investorRelationsOfficers = this.extractPositions(this.form.investorRelationsOfficersIds, cur);
            }
        },
    },
    mounted() {
        // this.users = [];
        this.fetchUsersList();
        this.fetchCommittees();

        if (this.report?.id) {
            this.matchForm(this.report);
        }
    },
    validations: {
        form: {
            boardOfDirectors: {
                required,
            },
            committees: {},
            executives: {
                required,
            },
            investorRelationsOfficers: {
                required,
            },
            companySecretaries: {
                required,
            },
            complianceOfficers: {
                required,
            },
            companyContactPersons: {
                required,
            },
        },
    },
});
