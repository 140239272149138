var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading,
      "id": "BA_AMP_STRUCTURES_PAGE"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray h5 title-text"
  }, [_vm._v("Governance Structures")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Directors",
      "label": 'Board of Directors',
      "listTitle": "Directors",
      "selected-label": 'Selected Director(s)',
      "create-label": 'Create New Director',
      "values": _vm.users,
      "defaultValue": _vm.form.boardOfDirectors,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.boardOfDirectors.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-director');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-director',
          data: $event,
          ref: 'createDirectorModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.boardOfDirectors.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one auditor")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.boardOfDirectors,
      callback: function ($$v) {
        _vm.$set(_vm.form, "boardOfDirectors", $$v);
      },
      expression: "form.boardOfDirectors"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "placeholder": "Search to select Committees",
      "label": 'Board committees',
      "listTitle": "Committees",
      "selected-label": 'Selected Committee(s)',
      "create-label": 'Create New Board Committee Structure',
      "values": _vm.committees,
      "defaultValue": _vm.form.committees,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.committees.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-committee');
      },
      "editValue": _vm.handleCommitteeEditValue
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.committees.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one committee")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.committees,
      callback: function ($$v) {
        _vm.$set(_vm.form, "committees", $$v);
      },
      expression: "form.committees"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Executives",
      "label": 'Executive management and leadership',
      "listTitle": "Executives",
      "selected-label": 'Selected Executive(s)',
      "create-label": 'Create New Executive',
      "values": _vm.users,
      "defaultValue": _vm.form.executives,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.executives.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-executive');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-executive',
          data: $event,
          ref: 'createExecutiveModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.executives.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one executive")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.executives,
      callback: function ($$v) {
        _vm.$set(_vm.form, "executives", $$v);
      },
      expression: "form.executives"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Officer(s)",
      "label": 'Investor Relations Officer(s)',
      "listTitle": "Investor Relations Officers",
      "selected-label": 'Selected Investor Relations Officer(s)',
      "create-label": 'Create New Investor Relations Officer',
      "values": _vm.users,
      "defaultValue": _vm.form.investorRelationsOfficers,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.investorRelationsOfficers.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-investor');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-investor',
          data: $event,
          ref: 'createInvestorModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.investorRelationsOfficers.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one investor relations officer")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.investorRelationsOfficers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "investorRelationsOfficers", $$v);
      },
      expression: "form.investorRelationsOfficers"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Secretary(s)",
      "label": 'Company Secretary(s)',
      "listTitle": "Company secretaries",
      "selected-label": 'Selected Company Secretary(s)',
      "create-label": 'Create New Company Secretary',
      "values": _vm.users,
      "defaultValue": _vm.form.companySecretaries,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.companySecretaries.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-secretary');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-secretary',
          data: $event,
          ref: 'createSecretaryModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.companySecretaries.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one company secretary")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.companySecretaries,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companySecretaries", $$v);
      },
      expression: "form.companySecretaries"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Compliance Officer(s)",
      "label": 'Compliance Officer(s)',
      "listTitle": "Compliance officers",
      "selected-label": 'Selected Compliance Officer(s)',
      "create-label": 'Create New Compliance Officer',
      "values": _vm.users,
      "defaultValue": _vm.form.complianceOfficers,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.complianceOfficers.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-compliance-officer');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-compliance-officer',
          data: $event,
          ref: 'createComplianceOfficerModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.complianceOfficers.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one compliance officers")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.complianceOfficers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "complianceOfficers", $$v);
      },
      expression: "form.complianceOfficers"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Contact Persons",
      "label": 'Company Contact Persons',
      "listTitle": "Members",
      "selected-label": 'Selected Company Contact Persons',
      "create-label": 'Create New Contact Person',
      "values": _vm.users,
      "defaultValue": _vm.form.companyContactPersons,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.companyContactPersons.$touch();
      },
      "create": function ($event) {
        _vm.saveDraft();
        _vm.isFromModal = true;
        _vm.$bvModal.show('modal-create-company-contact');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-company-contact',
          data: $event,
          ref: 'createCompanyContactModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.companyContactPersons.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one company contact person")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.companyContactPersons,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyContactPersons", $$v);
      },
      expression: "form.companyContactPersons"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mx-0 mt-5 report-button__wrapper"
  }, [_c('ba-button', {
    staticClass: "border bg-transparent border-black-10 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSavingDraft,
      "loading": _vm.isSavingDraft,
      "text": "Save and continue later"
    },
    on: {
      "click": function ($event) {
        _vm.saveDraft(true);
        _vm.isFromModal = false;
      }
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "text": "Back",
      "id": "BA_AMP_CANCEL_STRUCTURES",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), _c('ba-button', {
    staticClass: "ml-4 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Next",
      "id": "BA_AMP_NEXT_STRUCTURES",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        _vm.save(false);
        _vm.isFromModal = false;
      }
    }
  })], 1), _c('b-row', {
    staticClass: "mx-0 my-5"
  }, [_c('b-col', [_c('create-user-modal', {
    ref: "createDirectorModal",
    attrs: {
      "modalId": "create-director",
      "user-type": "Director",
      "edit": _vm.userData !== null,
      "user": _vm.userData,
      "hasPreferredPositions": "",
      "preferredPositions": _vm.directorPositionOptions,
      "show-roles": false
    },
    on: {
      "completed": function ($event) {
        _vm.userData = null;
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-director');
      },
      "resetEdit": function ($event) {
        _vm.userData = null;
      }
    }
  })], 1), _c('b-col', [_c('create-committee-modal', {
    ref: "createCommitteeModal",
    on: {
      "completed": function ($event) {
        _vm.fetchCommittees();
        _vm.$bvModal.hide('modal-create-committee');
      }
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    ref: "createExecutiveModal",
    attrs: {
      "modalId": "create-executive",
      "user-type": "Executive",
      "edit": _vm.userData !== null,
      "user": _vm.userData,
      "hasPreferredPositions": "",
      "preferredPositions": _vm.defaultPositionOptions,
      "show-roles": false
    },
    on: {
      "resetEdit": function ($event) {
        _vm.userData = null;
      },
      "completed": function ($event) {
        _vm.userData = null;
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-executive');
      }
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    ref: "createInvestorModal",
    attrs: {
      "modalId": "create-investor",
      "user-type": "Investor Relations Officer",
      "edit": _vm.userData !== null,
      "user": _vm.userData,
      "hasPreferredPositions": "",
      "preferredPositions": _vm.defaultPositionOptions,
      "show-roles": false
    },
    on: {
      "resetEdit": function ($event) {
        _vm.userData = null;
      },
      "completed": function ($event) {
        _vm.userData = null;
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-investor');
      }
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    ref: "createSecretaryModal",
    attrs: {
      "modalId": "create-secretary",
      "user-type": "Company Secretary",
      "edit": _vm.userData !== null,
      "user": _vm.userData,
      "hasPreferredPositions": "",
      "preferredPositions": _vm.defaultPositionOptions,
      "show-roles": false,
      "default-position": "Company Secretary"
    },
    on: {
      "resetEdit": function ($event) {
        _vm.userData = null;
      },
      "completed": function ($event) {
        _vm.userData = null;
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-secretary');
      }
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    ref: "createCompanyContactModal",
    attrs: {
      "modalId": "create-company-contact",
      "user-type": "Company Contact Person",
      "edit": _vm.userData !== null,
      "user": _vm.userData,
      "hasPreferredPositions": "",
      "preferredPositions": _vm.defaultPositionOptions,
      "show-roles": false,
      "default-position": "Company Secretary"
    },
    on: {
      "resetEdit": function ($event) {
        _vm.userData = null;
      },
      "completed": function ($event) {
        _vm.userData = null;
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-company-contact');
      }
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    ref: "createComplianceOfficerModal",
    attrs: {
      "modalId": "create-compliance-officer",
      "user-type": "Compliance Officer",
      "edit": _vm.userData !== null,
      "user": _vm.userData,
      "hasPreferredPositions": "",
      "preferredPositions": _vm.defaultPositionOptions,
      "show-roles": false,
      "default-position": "Compliance Officer"
    },
    on: {
      "resetEdit": function ($event) {
        _vm.userData = null;
      },
      "completed": function ($event) {
        _vm.userData = null;
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-compliance-officer');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }