
import Vue from "vue";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import PreviewReportModal from "@/app/components/modals/PreviewReportModal.vue";
import ReportConfirmationModal from "@/app/components/modals/ReportConfirmationModal.vue";
import ReportService from "../../../../api/report-service";
import { HOME } from "../../../routes/endpoints";
import UserService from "../../../../api/user-service";

export default Vue.extend({
    components: {
        CreateUserModal,
        PreviewReportModal,
        ReportConfirmationModal,
    },
    props: {
        report: Object,
        isLoading: { type: Boolean, default: false },
        isReassigningSignatory: { type: Boolean, default: false },
        haveUnresolvedComments: { type: Boolean, default: false },
    },
    data() {
        return {
            isSaving: false,
            isFetching: false,
            isSavingDraft: false,
            isFetchingPreview: false,
            isReassigning: false,
            form: {
                managingDirectorSignatories: [],
                chairmanOfBoardSignatories: [],
                executives: [],
                investorRelationsOfficers: [],
                companySecretaries: [],
                complianceOfficers: [],
                companyContactPersons: [],
                reviewers: [],
                reviewersIds: [],
            },
            allreviewers: [],
            signatoriesPositions: [
                {
                    position: "Board Chairman or Chairman, Committee responsible for Governance",
                    normalizedPosition: "SN3",
                    description: "This is the head of the department",
                    isRequired: true,
                    selected: [],
                },
                {
                    position: "Company Secretary or Compliance officer",
                    normalizedPosition: "SN4",
                    description: "",
                    isRequired: true,
                    selected: [],
                },
            ],
            reportPreview: {
                reportInformation: {
                    reportStatus: null,
                    reportCode: "",
                    currentDeskUser: {
                        email: "",
                        firstName: "",
                        id: "",
                        lastName: "",
                        profileImgId: "",
                        userName: "",
                    },
                    signatoryIds: [],
                },
                companyDetails: {},
                licenses: [],
                policies: [],
                registrarCompanies: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditors: [],
                charters: [],
                structure: {
                    commitees: [],
                    structurePositions: [],
                },
                principles: [],
                signatures: [],
            },
        };
    },
    computed: {
        ...mapState("users", ["users"]),
        ...mapState("users", {
            signatories: (state: any) => state.levels?.find((level: any) => level.category === "ReportSignatory")?.users,
            reviewers: (state: any) => state.levels?.find((level: any) => level.category === "ReportReviewer")?.users,
        }),
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
        }),
        handleUserEditValue({ modalId, data, ref }: { modalId: string; data: any; ref: string }) {
            //
            (this.$refs[ref] as any).fetchUser(data.id).then(() => {
                this.$bvModal.show(`${modalId}`);
            });
        },
        async reassignSignatories() {
            this.isReassigning = true;

            const { id } = this.$route.query;

            const body = this.signatoriesPositions.map((position) => {
                return {
                    position: position.normalizedPosition,
                    userId: (position?.selected[0] as any)?.id || "",
                };
            });

            try {
                const res = await ReportService.reassignSignatories({ id: id as string, body });
                this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
            } catch (error) {
                this.isReassigning = false;
            } finally {
                this.isReassigning = false;
            }
        },
        async save(isLater = false, bypassCheck = false) {
            if (isLater) this.isSavingDraft = true;
            else this.isSaving = true;

            if (!isLater && this.haveUnresolvedComments && !bypassCheck) {
                this.$bvModal.hide("modal-preview-report");
                this.isSaving = false;
                this.$bvModal.show("modal-report-confirmation-submit-report");
                return;
            }

            const { id } = this.$route.query;

            const body = this.signatoriesPositions.map((position) => {
                return {
                    position: position.normalizedPosition,
                    userId: (position?.selected[0] as any)?.id || "",
                };
            });

            try {
                const res = await ReportService.updateSignatories({
                    id: id as string,
                    body,
                    reviewerId: (this.form?.reviewers[0] as any)?.id,
                });

                this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });

                if (!isLater) {
                    await this.submitReport().then(async () => {
                        await this.$bvToast.toast("Report Submitted to Reviewer's email for Review successfully!", { title: "Success!", variant: "success", solid: true });

                        setTimeout(() => {
                            this.$router.replace({ name: HOME.REPORTS.NAME, query: { filter: "PROCESSING" } });
                        }, 2000);
                    });
                } else {
                    this.$emit("refresh");
                }
            } catch (error) {
                this.isSaving = false;
                this.isSavingDraft = false;
            } finally {
                this.isSaving = false;
                this.isSavingDraft = false;
            }
        },
        async submitReport() {
            try {
                this.isSaving = true;
                const { id } = this.$route.query;
                await ReportService.submitReport(id as string);
            } catch (error) {
                //
            }
        },
        cancel() {
            if (!this.isReassigningSignatory) {
                this.$emit("back");
            } else {
                this.$router.go(-1);
            }
        },
        async saveDraft() {
            await this.save(true);
            this.$router.push('/reports')
        },
        async fetchUserList() {
            try {
                this.isFetching = true;
                await this.fetchUsers();
            } catch (error) {
                this.isFetching = false;
            } finally {
                this.isFetching = false;
            }
        },
        getUser(userId: string) {
            return this.signatories.find((signatory: any) => signatory.id === userId);
        },
        matchForm(payload: any) {
            this.form.reviewersIds = [payload.details.reviewerId] as any;
            this.signatoriesPositions.forEach((position, index) => {
                this.signatoriesPositions[index].selected = [this.getUser(payload.signatories.find((signatory: any) => signatory.normalizedName === position.normalizedPosition)?.userId)] as any;
            });
        },
        async fetchReportById() {
            const { id } = this.$route.query;
            this.isFetchingPreview = true;
            try {
                const res = await ReportService.fetchReportById(id as string);
                this.reportPreview = res.data;
            } catch (error) {
                this.isFetchingPreview = false;
            } finally {
                this.isFetchingPreview = false;
            }
        },
        async fetchUserData() {
            const res = await UserService.fetchUsers();
            const reviewers = res.data.filter((data: any) => data.roleIds.includes("ReportReviewer"));
            this.allreviewers = reviewers;
        },
    },
    created() {
        if (this.report?.id) {
            this.matchForm(this.report);
        }
    },
    mounted() {
        this.fetchUserData();
        if (this.users.length === 0) {
            this.fetchUserList();
        }

        if (this.report?.id) {
            this.matchForm(this.report);
        }
    },
    watch: {
        report(curr, prev) {
            if (curr?.id && curr !== prev) {
                this.matchForm(curr);
            }
        },
        allreviewers: {
            handler(val) {
                const reviewer: any = [];

                this.form.reviewersIds.forEach((reviewerId: any) => {
                    const found = val.find((value: any) => value.id === reviewerId);
                    if (found) {
                        reviewer.push(found);
                    }
                });

                this.form.reviewers = reviewer;
            },
        },
    },
    validations: {
        form: {
            managingDirectorSignatories: {},
            chairmanOfBoardSignatories: {},
            executives: {},
            investorRelationsOfficers: {},
            complianceOfficers: {},
            companyContactPersons: {},
            reviewers: { required },
        },
        signatoriesPositions: {
            required,
            $each: {
                selected: {
                    maxLength: maxLength(1),
                    minLength: minLength(1),
                },
            },
        },
    },
});
