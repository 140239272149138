var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-2 mt-4"
  }, [_vm.question.header ? _c('span', {
    staticClass: "d-block question-name"
  }, [_vm._v(_vm._s(_vm.question.header))]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm.usage === 'principles' ? _c('div', {
    staticClass: "question-title"
  }, [_vm._v(" " + _vm._s(_vm.question.uniqueName) + " "), _vm.question.innerOrderIndex > 0 ? _c('span', [_vm._v("(" + _vm._s(String.fromCharCode(96 + _vm.question.innerOrderIndex)) + ")")]) : _vm._e(), _vm.question.isRequired ? _c('span', {
    staticClass: "text-danger h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "border border-gray-20 bg-gray-10 rounded-8 p-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "question-heading",
    domProps: {
      "innerHTML": _vm._s(_vm.question.question)
    }
  })]), _vm.question.type.includes(_vm.IQuestionType.BOOLEAN) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_DROPDOWN_AND_LONGTEXT) ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-form-radio', {
    staticClass: "question-radio cursor-pointer",
    attrs: {
      "id": `${_vm.question.uniqueName}-yes`,
      "name": _vm.question.unqiueName,
      "value": true
    },
    model: {
      value: _vm.form.booleanValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "booleanValue", $$v);
      },
      expression: "form.booleanValue"
    }
  }, [_vm._v("Yes")]), _c('b-form-radio', {
    staticClass: "mt-2 question-radio cursor-pointer",
    attrs: {
      "id": `${_vm.question.uniqueName}-no`,
      "name": _vm.question.unqiueName,
      "value": false
    },
    model: {
      value: _vm.form.booleanValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "booleanValue", $$v);
      },
      expression: "form.booleanValue"
    }
  }, [_vm._v("No")])], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.DROPDOWN) || _vm.question.type.includes(_vm.IQuestionType.DROPDOWN_AND_DATEPICKER) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_DROPDOWN_AND_LONGTEXT) || _vm.question.type.includes(_vm.IQuestionType.DROPDOWN_AND_LONGTEXT) ? _c('div', [_c('ba-dropdown', {
    staticClass: "bg-transparent",
    attrs: {
      "required": "",
      "label": _vm.usage === 'principles' ? 'Select Answer' : 'Options',
      "options": _vm.question.selectOptions
    },
    model: {
      value: _vm.form.stringValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "stringValue", $$v);
      },
      expression: "form.stringValue"
    }
  })], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.LONGTEXT) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA) || _vm.question.type.includes(_vm.IQuestionType.LONGTEXT_AND_MEDIA) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_DROPDOWN_AND_LONGTEXT) || _vm.question.type.includes(_vm.IQuestionType.DROPDOWN_AND_LONGTEXT) ? _c('div', [_c('ba-textarea', {
    attrs: {
      "required": "",
      "classText": "white",
      "label": "Explanation",
      "rows": 3,
      "placeholder": "Explain your response"
    },
    model: {
      value: _vm.form.stringValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "stringValue", $$v);
      },
      expression: "form.stringValue"
    }
  })], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.DATEPICKER) || _vm.question.type.includes(_vm.IQuestionType.TEXT_AND_DATEPICKER) || _vm.question.type.includes(_vm.IQuestionType.DROPDOWN_AND_DATEPICKER) ? _c('div', [_c('ba-datepicker', {
    attrs: {
      "required": "",
      "label": "Select Date",
      "classText": "white"
    },
    model: {
      value: _vm.form.dateValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dateValue", $$v);
      },
      expression: "form.dateValue"
    }
  })], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.TEXT) || _vm.question.type.includes(_vm.IQuestionType.TEXT_AND_DATEPICKER) ? _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Explanation",
      "placeholder": "Explain your response",
      "classText": "white"
    },
    model: {
      value: _vm.form.stringValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "stringValue", $$v);
      },
      expression: "form.stringValue"
    }
  })], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.NUMBER) ? _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Value",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.numericValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "numericValue", $$v);
      },
      expression: "form.numericValue"
    }
  })], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.MULTIPLE_DROPDOWN_AND_LONGTEXT) ? _c('div', [_c('ba-dropdown', {
    staticClass: "bg-transparent",
    attrs: {
      "required": "",
      "label": "Select Answer",
      "options": _vm.question.selectOptions,
      "multiple": "",
      "show-selection": true
    },
    model: {
      value: _vm.form.listValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "listValue", $$v);
      },
      expression: "form.listValue"
    }
  })], 1) : _vm._e(), _vm.question.type.includes(_vm.IQuestionType.MEDIA) || _vm.question.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA) || _vm.question.type.includes(_vm.IQuestionType.LONGTEXT_AND_MEDIA) || true ? _c('div', [_c('ba-upload-field', {
    attrs: {
      "saved-files": _vm.question.attachments,
      "loading": _vm.question.isUploading,
      "label": "Supporting document",
      "id": `${_vm.question.uniqueName}-${_vm.question.innerOrderIndex}`,
      "description": "Upload files"
    },
    on: {
      "input": function ($event) {
        return _vm.uploadFile($event, _vm.index);
      }
    }
  })], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }