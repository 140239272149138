var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading,
      "id": "BA_AMP_STAKE_HOLDERS_PAGE"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray h5 title-text"
  }, [_vm._v("Governance Stakeholders")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "label": 'External Auditors',
      "placeholder": "Search to select Auditor(s)",
      "listTitle": "Auditors",
      "selected-label": 'Selected Auditor(s)',
      "create-label": 'Create New Auditor',
      "values": _vm.auditors,
      "defaultValue": _vm.form.auditors,
      "loading-values": _vm.isFetchingAuditors,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.auditors.$touch();
      },
      "create": function ($event) {
        return _vm.$bvModal.show('modal-create-auditor');
      },
      "editValue": _vm.handleAuditorEditValue
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.auditors.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one auditor")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.auditors,
      callback: function ($$v) {
        _vm.$set(_vm.form, "auditors", $$v);
      },
      expression: "form.auditors"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "label": 'Governance Evaluation Consultant',
      "placeholder": "Search to select Consultant(s)",
      "selected-label": 'Selected Consultant(s)',
      "listTitle": "GE consultants",
      "create-label": 'Create New Consultant',
      "values": _vm.consultants,
      "loading-values": _vm.isFetchingConsultants,
      "defaultValue": _vm.form.governanceConsultants,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.governanceConsultants.$touch();
      },
      "create": function ($event) {
        return _vm.$bvModal.show('modal-create-governance-consultant');
      },
      "editValue": _vm.handleGovernanceConsultantEditValue
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.governanceConsultants.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one consultant")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.governanceConsultants,
      callback: function ($$v) {
        _vm.$set(_vm.form, "governanceConsultants", $$v);
      },
      expression: "form.governanceConsultants"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "label": 'Board Evaluation Consultant',
      "placeholder": "Search to select Consultant(s)",
      "selected-label": 'Selected Consultant(s)',
      "listTitle": "BE consultants",
      "create-label": 'Create New Consultant',
      "values": _vm.consultants,
      "loading-values": _vm.isFetchingConsultants,
      "defaultValue": _vm.form.boardConsultants,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.boardConsultants.$touch();
      },
      "create": function ($event) {
        return _vm.$bvModal.show('modal-create-board-consultant');
      },
      "editValue": _vm.handleBoardConsultantEditValue
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.boardConsultants.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one consultant")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.boardConsultants,
      callback: function ($$v) {
        _vm.$set(_vm.form, "boardConsultants", $$v);
      },
      expression: "form.boardConsultants"
    }
  })], 1), _c('section', [_c('div', {
    staticClass: "d-flex align-items-center px-4 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center px-0"
  }, [_c('h2', {
    staticClass: "text-dark-gray title-text-reg"
  }, [_vm._v("Registrar")]), _c('div', {
    staticClass: "title-line-reg"
  })]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-registrar",
      "name": "checkbox-registrar",
      "switch": ""
    },
    model: {
      value: _vm.form.isRegistrarSecretary,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isRegistrarSecretary", $$v);
      },
      expression: "form.isRegistrarSecretary"
    }
  }), _c('label', {
    staticClass: "registrar-text mb-0"
  }, [_vm._v("The Company Secretary serves as the Registrar of the Company and maintains the Register of Members of the Company")])], 1)])])], 1)], 1)], 1), !_vm.form.isRegistrarSecretary ? _c('b-row', {
    staticClass: "mx-0 mt-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-black h5"
  }, [_vm._v("Registrar")]), _c('div', {
    staticClass: "text-gray-800 text-sm-3"
  })]), _c('b-col', [_vm._l(_vm.form.registrars, function (registrar, ix) {
    return _c('b-row', {
      key: ix
    }, [_c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-end"
    }, [_c('ba-button', {
      staticClass: "px-0 text-danger text-decoration-none font-weight-bold text-right",
      attrs: {
        "icon": "ba-clear",
        "text": "Remove",
        "variant": "link"
      },
      on: {
        "click": function ($event) {
          return _vm.removeField('registrars', ix);
        }
      }
    })], 1)]), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('ba-text-field', {
      attrs: {
        "required": "",
        "label": "Full Name",
        "placeholder": "Enter Full name"
      },
      on: {
        "blur": function ($event) {
          return _vm.$v.form.registrars.$each[ix].name.$touch();
        }
      },
      model: {
        value: _vm.form.registrars[ix].name,
        callback: function ($$v) {
          _vm.$set(_vm.form.registrars[ix], "name", $$v);
        },
        expression: "form.registrars[ix].name"
      }
    }), _vm.$v.form.registrars.$each[ix].name.$error ? _c('div', {
      staticClass: "error"
    }, [_vm._v("Full Name is required")]) : _vm._e()], 1), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('ba-text-field', {
      attrs: {
        "required": "",
        "label": "Address",
        "placeholder": "Enter Address"
      },
      on: {
        "blur": function ($event) {
          return _vm.$v.form.registrars.$each[ix].address.$touch();
        }
      },
      model: {
        value: _vm.form.registrars[ix].address,
        callback: function ($$v) {
          _vm.$set(_vm.form.registrars[ix], "address", $$v);
        },
        expression: "form.registrars[ix].address"
      }
    }), _vm.$v.form.registrars.$each[ix].address.$error ? _c('div', {
      staticClass: "error"
    }, [_vm._v("Address is required")]) : _vm._e()], 1), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('ba-text-field', {
      attrs: {
        "required": "",
        "label": "Email",
        "placeholder": "Enter Email"
      },
      on: {
        "blur": function ($event) {
          return _vm.$v.form.registrars.$each[ix].email.$touch();
        }
      },
      model: {
        value: _vm.form.registrars[ix].email,
        callback: function ($$v) {
          _vm.$set(_vm.form.registrars[ix], "email", $$v);
        },
        expression: "form.registrars[ix].email"
      }
    }), _vm.$v.form.registrars.$each[ix].email.$error ? _c('div', {
      staticClass: "error"
    }, [!_vm.$v.form.registrars.$each[ix].email.required ? _c('div', [_vm._v("Registrar email is required")]) : _vm._e(), !_vm.$v.form.registrars.$each[ix].email.email ? _c('div', [_vm._v("Email is not valid")]) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('ba-phone-field', {
      attrs: {
        "required": "",
        "label": "Phone number",
        "type": "number",
        "placeholder": "Enter Phone Number"
      },
      on: {
        "blur": function ($event) {
          return _vm.$v.form.registrars.$each[ix].phone.$touch();
        }
      },
      model: {
        value: _vm.form.registrars[ix].phone,
        callback: function ($$v) {
          _vm.$set(_vm.form.registrars[ix], "phone", $$v);
        },
        expression: "form.registrars[ix].phone"
      }
    }), _vm.$v.form.registrars.$each[ix].phone.$error ? _c('div', {
      staticClass: "error"
    }, [!_vm.$v.form.registrars.$each[ix].phone.required ? _c('div', [_vm._v("Registrar Phone number is required")]) : _vm._e(), !_vm.$v.form.registrars.$each[ix].phone.numeric ? _c('div', [_vm._v("Phone Number is not valid")]) : _vm._e()]) : _vm._e()], 1), _vm.form.registrars.length > 1 ? _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('hr')]) : _vm._e()], 1);
  }), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-dashed-box', {
    staticClass: "pointer",
    attrs: {
      "stroke": "rgba(217, 229, 255, 1)"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.addRegistrar.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "text-blue-900 text-center py-3"
  }, [_vm._v("Add Registrar")])])], 1)], 1)], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "mx-0 my-5 report-button__wrapper"
  }, [_c('ba-button', {
    staticClass: "border bg-transparent border-black-10 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSavingDraft,
      "loading": _vm.isSavingDraft,
      "text": "Save and continue later"
    },
    on: {
      "click": function ($event) {
        return _vm.saveDraft();
      }
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "id": "BA_AMP_CANCEL_STAKE_HOLDERS",
      "text": "Back",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), _c('ba-button', {
    staticClass: "ml-4 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Next",
      "id": "BA_AMP_NEXT_STAKE_HOLDERS",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  })], 1), _c('b-row', {
    staticClass: "mx-0 mt-5"
  }, [_c('b-col', [_c('create-auditor-modal', {
    ref: "createAuditorModal",
    on: {
      "completed": function ($event) {
        _vm.fetchAuditors();
        _vm.$bvModal.hide('modal-create-auditor');
      }
    }
  })], 1), _c('b-col', [_c('create-consultant-modal', {
    ref: "createGovernanceConsultantModal",
    attrs: {
      "type": "Governance Evaluation Consultant",
      "modalId": "create-governance-consultant"
    },
    on: {
      "completed": function ($event) {
        _vm.fetchConsultants();
        _vm.$bvModal.hide('modal-create-governance-consultant');
      }
    }
  })], 1), _c('b-col', [_c('create-consultant-modal', {
    ref: "createBoardConsultantModal",
    attrs: {
      "type": "Board Evaluation Consultant",
      "modalId": "create-board-consultant"
    },
    on: {
      "completed": function ($event) {
        _vm.fetchConsultants();
        _vm.$bvModal.hide('modal-create-board-consultant');
      }
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    attrs: {
      "modalId": "create-registrar",
      "user-type": "Registrar",
      "hasPreferredPositions": "",
      "preferredPositions": _vm.registrarPositionOptions,
      "show-roles": false
    },
    on: {
      "completed": function ($event) {
        _vm.fetchUsersList();
        _vm.$bvModal.hide('modal-create-registrar');
      }
    }
  })], 1), _c('b-col', [_c('create-registrar-modal', {
    ref: "createRegistrarModal",
    on: {
      "completed": function ($event) {
        _vm.fetchAuditors();
        _vm.$bvModal.hide('modal-create-registrar');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }