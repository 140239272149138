var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading,
      "id": "BA_AMP_SIGNATORIES_PAGE"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray h5 title-text"
  }, [_vm._v("Reviewer")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-black-70 mb-3 text-sm-3"
  }, [_vm._v("Select the reviewer for this report")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('ba-selectable-file-input', {
    attrs: {
      "required": "",
      "placeholder": "Search to select Reviewer",
      "label": 'Report Reviewer',
      "listTitle": "Reviewers",
      "selected-label": 'Selected Reviewer',
      "create-label": 'Create New User',
      "values": _vm.reviewers,
      "loadingValues": _vm.isFetching,
      "type": "single",
      "defaultValue": _vm.form.reviewers,
      "is-value-editable": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.reviewers.$touch();
      },
      "create": function ($event) {
        return _vm.$bvModal.show('modal-create-user');
      },
      "editValue": function ($event) {
        return _vm.handleUserEditValue({
          modalId: 'modal-create-user',
          data: $event,
          ref: 'createUserModal'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.reviewers.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select one Reviewer")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.reviewers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reviewers", $$v);
      },
      expression: "form.reviewers"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('create-user-modal', {
    ref: "createUserModal",
    attrs: {
      "modalId": "create-user"
    },
    on: {
      "completed": function ($event) {
        _vm.fetchUserList();
        _vm.$bvModal.hide('modal-create-user');
      }
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray h5 title-text"
  }, [_vm._v("Signatories")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-black-70 mb-3 text-sm-3"
  }, [_vm._v("Select signatories that would sign this report")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', _vm._l(_vm.signatoriesPositions, function (signatoriesPosition, ix) {
    return _c('b-col', {
      key: ix,
      attrs: {
        "cols": "12"
      }
    }, [_c('ba-selectable-file-input', {
      attrs: {
        "label": signatoriesPosition.position,
        "placeholder": "Search to select Signatories",
        "selected-label": 'Selected Signatories',
        "listTitle": "Signatories",
        "create-label": 'Create New User',
        "values": _vm.signatories,
        "loadingValues": _vm.isFetching,
        "type": "single",
        "required": signatoriesPosition.isRequired,
        "defaultValue": _vm.signatoriesPositions[ix].selected,
        "is-value-editable": ""
      },
      on: {
        "create": function ($event) {
          return _vm.$bvModal.show('modal-create-user');
        },
        "blur": function ($event) {
          return _vm.$v.signatoriesPositions.$touch();
        },
        "editValue": function ($event) {
          return _vm.handleUserEditValue({
            modalId: 'modal-create-user',
            data: $event,
            ref: 'createUserModal'
          });
        }
      },
      scopedSlots: _vm._u([{
        key: "error",
        fn: function () {
          return [_vm.$v.signatoriesPositions.$error ? _c('div', {
            staticClass: "error"
          }, [_vm._v("Select at least one signatory")]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.signatoriesPositions[ix].selected,
        callback: function ($$v) {
          _vm.$set(_vm.signatoriesPositions[ix], "selected", $$v);
        },
        expression: "signatoriesPositions[ix].selected"
      }
    })], 1);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('create-user-modal', {
    ref: "createUserModal",
    attrs: {
      "modalId": "create-user"
    },
    on: {
      "completed": function ($event) {
        _vm.fetchUserList();
        _vm.$bvModal.hide('modal-create-user');
      }
    }
  })], 1), _c('b-col', [_c('preview-report-modal', {
    attrs: {
      "isLoading": _vm.isFetchingPreview,
      "report": _vm.reportPreview
    },
    on: {
      "close": function ($event) {
        return _vm.$bvModal.hide('modal-preview-report');
      },
      "submit": function ($event) {
        _vm.save(false);
        _vm.$bvModal.hide('modal-preview-report');
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "mx-0 my-5 report-button__wrapper"
  }, [_c('BaButton', {
    staticClass: "border bg-transparent border-black-10 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSavingDraft || _vm.isSaving,
      "loading": _vm.isSavingDraft,
      "text": "Save and continue later"
    },
    on: {
      "click": _vm.saveDraft
    }
  }), _c('BaButton', {
    staticClass: "text-gray-dark mr-5",
    attrs: {
      "id": "BA_AMP_CANCEL_SIGNATORIES",
      "text": "Back",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), !_vm.isReassigningSignatory ? _c('div', {
    staticClass: "d-flex"
  }, [_c('BaButton', {
    staticClass: "border bg-transparent border-black-10 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSavingDraft || _vm.isSaving,
      "text": "Preview Report"
    },
    on: {
      "click": function ($event) {
        _vm.fetchReportById();
        _vm.$bvModal.show('modal-preview-report');
      }
    }
  })], 1) : _vm._e(), !_vm.isReassigningSignatory ? _c('BaButton', {
    staticClass: "border-0 bg-green-50 text-white",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving || _vm.isSavingDraft,
      "loading": _vm.isSaving,
      "text": "Submit For Review",
      "id": "BA_AMP_SUBMIT_FOR_REVIEW"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  }) : _c('BaButton', {
    staticClass: "border-0 bg-green-50 text-white",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isReassigning,
      "loading": _vm.isReassigning,
      "text": "Reassign Signatories"
    },
    on: {
      "click": _vm.reassignSignatories
    }
  })], 1), _c('ReportConfirmationModal', {
    attrs: {
      "is-loading": _vm.isSaving,
      "confirmation-type": "submit-report"
    },
    on: {
      "submit-report": function ($event) {
        return _vm.save(false, true);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }