var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading,
      "id": "BA_AMP_PRINCIPLES_PAGE"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray h5 title-text"
  }, [_vm._v("Principles")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-black-70 text-sm-3"
  }, [_vm._v(" Respond to various questions and comments regarding your Company's compliance with the requirements of the Nigeria Code of Corporate Governance 2018 ")]), _c('div', {
    staticClass: "bg-yellow-10 d-flex p-3 rounded-4 my-3"
  }, [_c('ba-base-icon', {
    staticClass: "mr-2 mt-1",
    attrs: {
      "name": "ba-info-dark"
    }
  }), _c('div', [_c('p', {
    staticClass: "mb-1 note-title"
  }, [_vm._v("Please note")]), _c('ol', [_c('li', [_vm._v("Every line item and indicator must be completed.")]), _c('li', [_vm._v("Respond to each question with “Yes” where you have applied the principle, and “No” where you are yet to apply the principle.")]), _c('li', [_vm._v("An explanation on how you are applying the principle, or otherwise should be included as part of your response.")]), _c('li', [_vm._v("Not Applicable (N/A) is not a valid response.")])])])], 1)]), _c('b-col', [_c('b-row', _vm._l(_vm.allPrinciples, function (principle, ix) {
    return _c('b-col', {
      key: ix,
      staticClass: "mb-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('principle-input', {
      attrs: {
        "principle": principle,
        "index": ix
      }
    })], 1);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "mx-0 my-5 report-button__wrapper"
  }, [_c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "id": "BA_AMP_CANCEL_PRINCIPLES",
      "text": "Cancel",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), _c('ba-button', {
    staticClass: "ml-4 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Next",
      "id": "BA_AMP_NEXT_PRINCIPLES",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  })], 1), _c('div', {
    staticClass: "my-5",
    class: {
      'text-right': !_vm.footerFixed
    }
  }, [_c('button', {
    staticClass: "btn button__class text-gray-dark btn-warning",
    class: _vm.footerFixed ? 'fixed' : '',
    on: {
      "click": _vm.toTop
    }
  }, [_vm._v("Back to top")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }