
import Vue from "vue";
import ReportService from "@/api/report-service";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
    name: "CreateBoardCommitteeModal",
    props: {},
    components: { CreateUserModal },
    data() {
        return {
            isEdit: false,
            isSaving: false,
            isUploading: false,
            isFetchingCommittee: false,
            form: {
                id: "",
                committeeName: "",
                committeeDescription: "",
                mandateSummary: "",
                committeeMembers: [],
                mandateUpload: {
                    docUploadId: "",
                    fileName: "",
                    size: "",
                    version: "",
                },
            },
        };
    },
    computed: {
        ...mapState("users", ["users"]),
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchFile: "file/fetchFile",
        }),
        async uploadFile(file: any) {
            const { docUploadId, fileName, size, version } = file[0];
            this.form.mandateUpload = {
                docUploadId,
                fileName,
                size,
                version,
            };
        },
        async createCommittee() {
            this.isSaving = true;

            const body = {
                description: this.form.committeeDescription,
                mandateSummary: this.form.mandateSummary,
                mandateUpload: this.form.mandateUpload?.docUploadId,
                memberIds: this.form.committeeMembers.map((member: any) => {
                    return member.id;
                }),
                name: this.form.committeeName,
            };
            try {
                if (!this.isEdit) {
                    const res = await ReportService.createCommittee(body);
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                } else {
                    const res = await ReportService.updateCommittee({
                        id: this.form.id,
                        body,
                    });
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                }

                this.$emit("completed");
                this.resetData();
            } catch (error) {
                this.isSaving = false;
                this.isEdit = false;
            } finally {
                this.isSaving = false;
                this.isEdit = false;
            }
        },
        async fetchUserList() {
            try {
                this.isFetchingCommittee = true;
                this.fetchUsers();
            } catch (error) {
                this.isFetchingCommittee = false;
            } finally {
                this.isFetchingCommittee = false;
            }
        },
        closeModal () {
            this.resetData()
            this.$bvModal.hide("modal-create-committee");
        },
        resetData() {
            this.isEdit = false;
            this.form = {
                id: "",
                committeeName: "",
                committeeDescription: "",
                mandateSummary: "",
                committeeMembers: [],
                mandateUpload: {
                    docUploadId: "",
                    fileName: "",
                    size: "",
                    version: "",
                },
            };
        },
        setForm(data: any) {
            this.form.id = data.id;
            this.form.committeeName = data.name;
            this.form.committeeDescription = data.description;
            this.form.mandateSummary = data.mandateSummary;
            this.form.mandateUpload.docUploadId = data.mandateUpload;
            this.form.committeeMembers = data.members;
        },
        async fetchCommittee(id: string) {
            //
            console.log(this.isEdit);
            console.log("committee", id);
            this.isEdit = true;
            try {
                this.isFetchingCommittee = true;
                const { data } = await ReportService.fetchCommitteeById(id);

                this.setForm(data);
            } catch (error) {
                this.isFetchingCommittee = false;
            } finally {
                this.isFetchingCommittee = false;
            }
        },
    },

    mounted() {
        this.fetchUserList();
    },
    validations() {
        return {
            form: {
                id: {},
                committeeName: {
                    required,
                },
                committeeDescription: {
                    required,
                },
                mandateSummary: {
                    required,
                },
                committeeMembers: {
                    required,
                },
            },
        };
    },
});
