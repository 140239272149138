
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { required, numeric, email } from "vuelidate/lib/validators";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import CreateAuditorModal from "@/app/components/modals/CreateAuditorModal.vue";
import CreateConsultantModal from "@/app/components/modals/CreateConsultantModal.vue";
import CreateRegistrarModal from "@/app/components/modals/CreateRegistrarModal.vue";
import ReportService from "../../../../api/report-service";

type IStakeholderForm = {
    governanceConsultantIds: string[];
    governanceConsultants: string[];
    isRegistrarSecretary: boolean;
    boardConsultantIds: string[];
    boardConsultants: string[];
    auditorIds: string[];
    registrarsIds: string[];
    registrars: {
        name: string;

        email: string;
        phone: string;
        address: string;
    }[];
    auditors: any;
};

export default Vue.extend({
    components: {
        CreateAuditorModal,
        CreateConsultantModal,
        CreateUserModal,
        CreateRegistrarModal,
    },
    props: {
        report: Object,
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data(): {
        isSaving: boolean;
        fetchingUser: boolean;
        isSavingDraft: boolean;
        isFetchingAuditors: boolean;
        isFetchingRegistrars: boolean;
        isFetchingConsultants: boolean;
        form: IStakeholderForm;
        auditors: any;
        registrars: any;
        consultants: any;
        boardConsultants: any;
        charterTypeOptions: string[];
        registrarPositionOptions: string[];
        isFromModal: boolean;
    } {
        return {
            isSaving: false,
            fetchingUser: false,
            isSavingDraft: false,
            isFetchingAuditors: false,
            isFetchingRegistrars: false,
            isFetchingConsultants: false,
            form: {
                boardConsultantIds: [],
                governanceConsultantIds: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditorIds: [],
                registrars: [],
                registrarsIds: [],
                auditors: [],
                isRegistrarSecretary: false,
            },
            auditors: [],
            registrars: [],
            consultants: [],
            boardConsultants: [],
            charterTypeOptions: ["Memorandum and Articles of Association", "Company Constitution", "Corporate Charter", "Rules and Guidelines", "Others"],
            registrarPositionOptions: ["Executive Director", "Non-Executive", "Independent Non-Executive Director", "Managing Director", "Chairman", "Legal Adviser"],
            isFromModal: false,
        };
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
        }),

        addRegistrar() {
            this.form.registrars.push({
                name: "",

                email: "",
                phone: "",
                address: "",
            });
        },
        handleAuditorEditValue(val: any) {
            //
            (this.$refs.createAuditorModal as any).fetchAuditor(val.id).then(() => {
                this.$bvModal.show("modal-create-auditor");
            });
        },
        handleBoardConsultantEditValue(val: any) {
            //
            (this.$refs.createBoardConsultantModal as any).fetchConsultant(val.id).then(() => {
                this.$bvModal.show("modal-create-board-consultant");
            });
        },
        handleGovernanceConsultantEditValue(val: any) {
            //
            (this.$refs.createGovernanceConsultantModal as any).fetchConsultant(val.id).then(() => {
                this.$bvModal.show("modal-create-governance-consultant");
            });
        },
        removeField(key: string, index: number) {
            this.form[key as keyof IStakeholderForm].splice(index, 1);
        },

        async save(isLater = false) {
            if (isLater) this.isSavingDraft = true;
            else this.isSaving = true;

            const { id } = this.$route.query;

            const body = {
                isRegistrarSecretary: this.form.isRegistrarSecretary,
                boardConsultantIds: this.form.boardConsultants.map((val: any) => val.id),
                governanceConsultantIds: this.form.governanceConsultants.map((val: any) => val.id),
                auditorIds: this.form.auditors.map((val: any) => val.id),
                // send empty list for now because design varies from backend
                registrarList: this.form.isRegistrarSecretary
                    ? []
                    : this.form.registrars.map((registrar) => {
                          return {
                              email: registrar.email,
                              name: registrar.name,
                              phoneNumber: registrar.phone,
                              address: registrar.address,
                          };
                      }),
            };
            try {
                const res = await ReportService.updateStakeholderDetails({
                    id: id as string,
                    body,
                });
                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                if (!isLater) {
                    this.$emit("completed");
                }
                this.$emit("refresh");
                // console.log(res.data);
            } catch (error) {
                this.isSaving = false;
                this.isSavingDraft = false;
            } finally {
                this.isSaving = false;
                this.isSavingDraft = false;
            }
        },
        async saveDraft() {
            await this.save(true);
            this.$router.push('/reports')
        },
        cancel() {
            this.$emit("back");
        },
        fetchUsersList() {
            try {
                this.fetchingUser = true;
                this.fetchUsers();
            } catch (error) {
                this.fetchingUser = false;
            }
        },
        matchForm(payload: any) {
            this.form.auditorIds = payload.profile?.auditorIds;
            this.form.governanceConsultantIds = payload.profile?.governanceConsultantIds;
            this.form.boardConsultantIds = payload.profile?.boardConsultantIds;
            this.form.isRegistrarSecretary = payload.profile.isRegistrarSecretary;

            this.form.registrars = payload.profile.registrarList.map((registrar: any) => {
                return {
                    name: registrar.name,
                    email: registrar.email,
                    phone: registrar.phoneNumber,
                    address: registrar.address,
                };
            });
        },
        getAvatarText(val: string) {
            let avatarText;
            const splitName = val.split(" ");
            const [firstName, lastName] = val;

            if (splitName.length > 1) {
                avatarText = `${splitName[0].charAt(0).toUpperCase()}${splitName[1].charAt(0).toUpperCase()}`;
            } else {
                avatarText = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
            }

            return avatarText;
        },
        async fetchAuditors() {
            try {
                this.isFetchingAuditors = true;
                const res = await ReportService.fetchAuditors();
                this.auditors = res.data.map((val: any) => {
                    const avatarText = this.getAvatarText(val.name);

                    return {
                        ...val,
                        avatarText,
                        roles: "Auditor",
                        fullName: val.name,
                    };
                });
            } catch (error) {
                this.isFetchingAuditors = false;
            } finally {
                this.isFetchingAuditors = false;
            }
        },
        async fetchConsultants() {
            try {
                this.isFetchingConsultants = true;
                const res = await ReportService.fetchConsultants();
                this.consultants = res.data.map((val: any) => {
                    const avatarText = this.getAvatarText(val.name);

                    return {
                        ...val,
                        avatarText,
                        roles: "Consultant",
                        fullName: val.name,
                    };
                });
            } catch (error) {
                this.isFetchingConsultants = false;
            } finally {
                this.isFetchingConsultants = false;
            }
        },
    },
    computed: {
        ...mapState("users", ["users"]),
    },
    mounted() {
        this.fetchAuditors();
        this.fetchConsultants();
        if (this.report?.id) {
            this.matchForm(this.report);
        }
    },
    watch: {
        "report": {
            handler(curr, prev) {
                if (curr?.id && curr !== prev) {
                    this.matchForm(curr);
                }
            },
        },
        "auditors": {
            handler(val) {
                const auditors: any = [];

                this.form.auditorIds.forEach((auditorId: any) => {
                    const found = val.find((value: any) => value.id === auditorId);
                    if (found) {
                        auditors.push(found);
                    }
                });

                this.form.auditors = auditors;
            },
        },
        "consultants": {
            handler(val) {
                const boardConsultants: any = [];
                const governanceConsultants: any = [];

                this.form.boardConsultantIds.forEach((consultantId: any) => {
                    const found = val.find((value: any) => value.id === consultantId);
                    if (found) {
                        boardConsultants.push(found);
                    }
                });

                this.form.governanceConsultantIds.forEach((consultantId: any) => {
                    const found = val.find((value: any) => value.id === consultantId);
                    if (found) {
                        governanceConsultants.push(found);
                    }
                });

                this.form.boardConsultants = boardConsultants;
                this.form.governanceConsultants = governanceConsultants;
            },
        },
        "form.isRegistrarSecretary": {
            handler(v) {
                // console.log(v);
                if (v) {
                    this.form.registrars = [];
                }
            },
            deep: true,
        },
    },

    validations: {
        form: {
            governanceConsultants: {},
            boardConsultants: {},
            auditors: {},
            registrars: {
                $each: {
                    name: {
                        required,
                    },

                    email: {
                        required,
                        email,
                    },
                    phone: {
                        required,
                        numeric,
                    },
                    address: {
                        required,
                    },
                },
            },
        },
    },
});
