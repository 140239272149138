var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": `${_vm.modalId}`,
      "title": `${_vm.isEdit ? 'Update' : 'Create'} ${_vm.type}`,
      "description": `${_vm.isEdit ? 'Update' : 'Add a new'} ${_vm.type}`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    },
    on: {
      "hide": _vm.resetData
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Name of consultant",
      "placeholder": "E.g John"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.fullName.$touch();
      }
    },
    model: {
      value: _vm.form.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fullName", $$v);
      },
      expression: "form.fullName"
    }
  }), _vm.$v.form.fullName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Name is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Email address",
      "placeholder": "E.g martin@nccg.gov.ng"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.email.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm.$v.form.email.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Email is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-phone-field', {
    staticClass: "text-black-90",
    attrs: {
      "label": "Phone number",
      "classText": "white",
      "required": "",
      "placeholder": "Phone number"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.phone.$touch();
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _vm.$v.form.phone.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Phone Number is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Primary Address",
      "placeholder": "Primary Address"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.primaryAddress.$touch();
      }
    },
    model: {
      value: _vm.form.primaryAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "primaryAddress", $$v);
      },
      expression: "form.primaryAddress"
    }
  }), _vm.$v.form.primaryAddress.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Primary Address is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-datepicker', {
    attrs: {
      "required": "",
      "label": "Date of appointment"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.dateOfAppointment.$touch();
      }
    },
    model: {
      value: _vm.form.dateOfAppointment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dateOfAppointment", $$v);
      },
      expression: "form.dateOfAppointment"
    }
  }), _vm.$v.form.dateOfAppointment.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Date of Appointment is required ")]) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "text": "Cancel",
      "variant": "light"
    },
    on: {
      "click": _vm.closeModal
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "loading": _vm.isSaving || _vm.isFetchingConsultant,
      "disabled": _vm.$v.$invalid || _vm.isSaving || _vm.isFetchingConsultant,
      "text": `${_vm.isEdit ? 'Update' : 'Save'}`,
      "variant": "warning"
    },
    on: {
      "click": _vm.createConsultant
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }