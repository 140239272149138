
import Vue from "vue";
import { mapState } from "vuex";
import { DateTime } from "luxon";
import { required } from "vuelidate/lib/validators";
import ReportService from "@/api/report-service";

export default Vue.extend({
    props: {
        report: Object,
    },
    data() {
        return {
            isSaving: false,
            isSavingDraft: false,
            isLoading: false,
            form: {
                title: "",
                reference: "",
                reportingFrom: "",
                reportingTo: "",
                sectorialRegulator: "",
                otherRegulator: "",
                attachments: [
                    // {
                    //     label: "Proof of business License",
                    //     key: "BUSINESS_LICENSE",
                    //     multiple: true,
                    //     isUploading: false,
                    //     files: [],
                    //     normalizedName: "D1",
                    // },
                    {
                        label: "Board and Committee Attendance",
                        desc: "You can add multiple Attendance Registers in one single document upload or as separate document uploads",
                        key: "ATTENDANCE_REGISTER",
                        multiple: true,
                        isUploading: false,
                        files: [],
                        normalizedName: "D2",
                        title: "Attendance Register"
                    },
                    // {
                    //     label: "Memorandum & Articles of Association",
                    //     key: "MEMORANDUM_OF_ASSOCIATION",
                    //     multiple: false,
                    //     isUploading: false,
                    //     files: [],
                    //     normalizedName: "D3",
                    // },
                    // {
                    //     label: "Certificate of Incorporation",
                    //     key: "CERTIFICATE_OF_INCORPORATION",
                    //     multiple: false,
                    //     isUploading: false,
                    //     files: [],
                    //     normalizedName: "D5",
                    // },
                    // {
                    //     label: "Minute Book",
                    //     key: "MINUTE_BOOK",
                    //     multiple: true,
                    //     isUploading: false,
                    //     files: [],
                    //     normalizedName: "D6",
                    // },
                    // {
                    //     label: "Proof of registered address",
                    //     key: "PROOF_OF_BUSINESS",
                    //     multiple: true,
                    //     isUploading: false,
                    //     files: [],
                    //     normalizedName: "D7",
                    // },
                    {
                        label: "Primary Operating License",
                        key: "PRIMARY_OPERATING_LICENSE",
                        multiple: true,
                        isUploading: false,
                        files: [],
                        normalizedName: "D9",
                        title: "Primary Operating License",
                    },
                    {
                        label: "Other documents",
                        desc: "You can add any other document you wish to submit to the FRC in one single document upload or as separate document uploads",
                        key: "OTHERS",
                        multiple: true,
                        isUploading: false,
                        files: [],
                        normalizedName: "D4",
                        title: "Other documents",
                    },
                ] as any,
            },
            sectorialRegulatorOptions: [],
            otherRegulatorOptions: [],
        };
    },
    computed: {
        ...mapState("reports", ["existingReports"]),
    },
    methods: {
        async save(isLater = false) {
            if (isLater) this.isSavingDraft = true;
            else this.isSaving = true;

            const { id } = this.$route.query;
            const { attachments } = this.form;
            const body = attachments.map((attachment: any) => ({
                name: attachment.normalizedName,
                attatchments: attachment.files,
            }));
            try {
                const res = await ReportService.updateReportDocuments({
                    id: id as string,
                    body,
                });

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                // console.log(res.data);
                if (!isLater) {
                    this.$emit("completed");
                }
                this.$emit("refresh");
            } catch (error) {
                this.$bvToast.toast("Something went wrong - this will be corrected", { title: "Success!", variant: "danger", solid: true });
                this.isSaving = false;
                this.isSavingDraft = false;
            } finally {
                this.isSaving = false;
                this.isSavingDraft = false;
            }
        },
        async saveDraft() {
            await this.save(true);
            this.$router.push('/reports')
        },
        cancel() {
            this.$emit("back");
        },
        matchForm(payload: any) {
            const { documents } = payload;
            const temp = this.form.attachments.map((attachment: any) => {
                const item = documents.filter((x: any) => x.name === attachment.title).pop();
                return {
                    ...attachment,
                    files: [...item.attatchments],
                };
            });
            this.form = { ...this.form, attachments: temp };
        },
        async fetchProfile() {
            //
        },
        async uploadFile(fileArray: any, index: number) {
            this.form.attachments[index].files = [...fileArray];
        },
    },
    mounted() {
        if (this.report?.id) {
            this.matchForm(this.report);
        }
    },
    watch: {
        report(curr, prev) {
            if (curr?.id && curr !== prev) {
                this.matchForm(curr);
            }
        },
    },
    validations: {
        form: {
            title: {},
            reference: {},
            reportingFrom: {},
            reportingTo: {},
            sectorialRegulator: {},
            otherRegulator: {},
        },
    },
});
