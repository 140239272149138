var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "preview-selectable-modal preview-selectable-modal_container",
    attrs: {
      "id": 'preview-report',
      "title": 'Preview Report',
      "description": '',
      "custom-content-class": "overflow-scroll preview-selectable-modal__content",
      "size": "xl",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('b-row', {
    staticStyle: {
      "margin-top": "-50px",
      "margin-bottom": "50px"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('p', {
    staticClass: "mb-1 company-text"
  }, [_vm._v("Company Name: " + _vm._s(_vm.companyData.companyProfile.name))]), _c('p', [_vm._v("RC Number: RC (" + _vm._s(_vm.companyData.companyProfile.rcNumber) + ")")])]), _c('company', {
    attrs: {
      "report": _vm.report,
      "isPreview": "",
      "isLoading": _vm.isLoading
    }
  }), _c('stakeholders', {
    attrs: {
      "report": _vm.report,
      "isPreview": "",
      "isLoading": _vm.isLoading
    }
  }), _c('structure', {
    attrs: {
      "report": _vm.report,
      "isPreview": "",
      "isLoading": _vm.isLoading
    }
  }), _c('documentation', {
    attrs: {
      "report": _vm.report,
      "isPreview": "",
      "isLoading": _vm.isLoading
    }
  }), _c('principles', {
    attrs: {
      "report": _vm.report,
      "isPreview": "",
      "isLoading": _vm.isLoading
    }
  }), _c('certification', {
    attrs: {
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "isPreview": ""
    }
  })], 1), _c('b-col', {
    staticClass: "d-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    ref: "document",
    attrs: {
      "id": "doc1"
    }
  }, [_c('div', {
    staticClass: "px-4",
    attrs: {
      "aria-label": "pdf-page-1"
    }
  }, [_c('p', {
    staticClass: "mb-1 company-text"
  }, [_vm._v("Company Name: " + _vm._s(_vm.companyData.companyProfile.name))]), _c('p', [_vm._v("RC Number: RC (" + _vm._s(_vm.companyData.companyProfile.rcNumber) + ")")])]), _c('company', {
    attrs: {
      "aria-label": "pdf-page-1",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "isPreview": ""
    }
  }), _c('documentation', {
    attrs: {
      "aria-label": "pdf-page-3",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "isPreview": ""
    }
  }), _c('stakeholders', {
    attrs: {
      "aria-label": "pdf-page-4",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "isPreview": ""
    }
  }), _c('structure', {
    attrs: {
      "aria-label": "pdf-page-5",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "isPreview": ""
    }
  }), _vm._l(_vm.report.principles, function (principle, ix) {
    return _c('div', {
      key: ix,
      attrs: {
        "aria-label": `pdf-page-${ix + 7}`
      }
    }, [_c('p', {
      staticClass: "structure-title"
    }, [_vm._v(_vm._s(principle.name) + " : " + _vm._s(principle.title))]), _c('p', {
      staticClass: "principle-desc"
    }, [_vm._v(_vm._s(principle.description))]), _vm._l(principle.inputModels, function (inputModel, idx) {
      return _c('div', {
        key: idx,
        staticClass: "mb-4"
      }, [_c('question-output', {
        attrs: {
          "inputModel": inputModel,
          "principle": principle,
          "index": idx,
          "isRegulatorValid": false
        },
        on: {
          "inputModel": _vm.sendFlaggedProps
        }
      })], 1);
    })], 2);
  }), _c('certification', {
    attrs: {
      "aria-label": "pdf-page-80",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "isPreview": ""
    }
  })], 2)])], 1), _c('div', {
    staticClass: "footer-section"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-gray-dark bg-white border border-gray-40",
    attrs: {
      "disabled": _vm.isDownloading,
      "loading": _vm.isDownloading,
      "text": "Download Draft Report",
      "id": "BA_AMP_PRINT_AS_PDF",
      "icon": "ba-pdf-download"
    },
    on: {
      "click": _vm.exportPdf
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('ba-button', {
    staticClass: "text-gray-dark border-0 bg-gray-40 mr-3",
    attrs: {
      "disabled": _vm.isDownloading,
      "text": "Continue editing",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "disabled": _vm.isDownloading,
      "text": "Submit For Review",
      "id": "BA_AMP_SUBMIT_FOR_REVIEW_TWO",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('submit');
      }
    }
  })], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }