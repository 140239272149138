var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading,
      "id": "BA_AMP_DOCUMENTATION_PAGE"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray h5 title-text"
  }, [_vm._v("Documentation")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', [_c('b-row', _vm._l(_vm.form.attachments, function (attachment, ix) {
    return _c('b-col', {
      key: ix,
      staticClass: "mt-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('ba-upload-field', {
      staticClass: "mb-4",
      attrs: {
        "saved-files": attachment.files,
        "loading": attachment.isUploading,
        "label": attachment.label,
        "id": attachment.key,
        "tooltipTitle": attachment.desc,
        "description": `Upload ${!attachment.multiple ? 'a pdf' : 'files'}`
      },
      on: {
        "input": function ($event) {
          return _vm.uploadFile($event, ix);
        }
      }
    })], 1);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "mx-0 my-5 report-button__wrapper"
  }, [_c('ba-button', {
    staticClass: "border bg-transparent border-black-10 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSavingDraft,
      "loading": _vm.isSavingDraft,
      "text": "Save and continue later"
    },
    on: {
      "click": _vm.saveDraft
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "id": "BA_AMP_CANCEL_DOCUMENTATION",
      "text": "Cancel",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), _c('ba-button', {
    staticClass: "ml-4 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Next",
      "id": "BA_AMP_NEXT_DOCUMENTATION",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }