
import Vue from "vue";
import QuestionInput from "./QuestionInput.vue";
import ReportService from "../../../api/report-service";

type IPrincipleType = {
    name: string;
    title: string;
    description: string;
    hasFee: boolean;
    fee: number;
    normalizedName: string;
    isImportant: boolean;
    questions: [
        {
            question: string;
            isRequired: boolean;
            uniqueName: string;
            orderIndex: number;
            innerOrderIndex: number;
            dataType: number;
            selectOptions: [];
            allowedDocType: string[];
            isFlaggable: boolean;
        },
    ];
    orderIndex: number;
};

export default Vue.extend({
    props: {
        principle: {
            required: true,
            type: Object as () => IPrincipleType,
        },
        index: Number,
        submitText: {
            type: String,
            required: false,
            default: "Save principle",
        },
        usage: {
            type: String,
            required: false,
            default: "principles",
        },
    },
    components: {
        QuestionInput,
    },
    data(): {
        isSaving: boolean;
        form: {
            principle: string;
            answers: {
                attatchments: any;
                booleanValue: boolean;
                dateValue: string | null;
                listValue: any;
                numericValue: number;
                stringValue: string | null;
                timeOfAction: string | null;
                uniqueName: string;
            }[];
        };
        otherDocumentations: { id: string }[];
    } {
        return {
            isSaving: false,
            form: {
                principle: "",
                answers: [],
            },
            otherDocumentations: [{ id: "1" }],
        };
    },
    methods: {
        async save(index: number) {
            const body = this.form.answers.map((answer) => {
                return {
                    inputIdentifier: answer.uniqueName,
                    stringValue: answer.stringValue,
                    numericValue: Number(answer.numericValue) || 0,
                    booleanValue: answer.booleanValue,
                    dateValue: answer.dateValue || null,
                    listValue: answer.listValue,
                    attatchments: answer.attatchments,
                };
            });

            const reportId = this.$route.query.id;

            this.isSaving = true;
            try {
                const res = await ReportService.updatePrinciple({
                    reportId: reportId as string,
                    principleName: this.principle.normalizedName,
                    body,
                });
                // console.log(res.data);
                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                // this.$root.$emit("bv::toggle::collapse", `principle-${index}`);
                this.$root.$emit("bv::toggle::collapse", `principle-${index + 1}`);
            } catch (error) {
                this.isSaving = false;
            } finally {
                this.isSaving = false;
            }
        },
        handleQuestionInput(evt: any) {
            this.form.answers[evt.index] = {
                ...this.form.answers[evt.index],
                ...evt.val,
            };
        },
        setupForm() {
            this.form.answers = this.principle.questions.map((question: any) => ({
                attatchments: [],
                booleanValue: false,
                dateValue: null,
                listValue: [],
                numericValue: 0,
                stringValue: null,
                timeOfAction: null,
                uniqueName: question.uniqueName,
                ...question,
            }));
        },
    },
    validations: {
        form: {},
    },

    mounted() {
        this.setupForm();
    },
});
