var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": 'create-committee',
      "title": `${_vm.isEdit ? 'Update' : 'Create'} Committee`,
      "description": `${_vm.isEdit ? 'Update' : 'Create'} board or management committee`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    },
    on: {
      "hide": _vm.resetData
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Committee name",
      "placeholder": "E.g John's Committee"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.committeeName.$touch();
      }
    },
    model: {
      value: _vm.form.committeeName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "committeeName", $$v);
      },
      expression: "form.committeeName"
    }
  }), _vm.$v.form.committeeName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Committee Name is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Committee Description",
      "placeholder": "e.g This is a good Committee"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.committeeDescription.$touch();
      }
    },
    model: {
      value: _vm.form.committeeDescription,
      callback: function ($$v) {
        _vm.$set(_vm.form, "committeeDescription", $$v);
      },
      expression: "form.committeeDescription"
    }
  }), _vm.$v.form.committeeDescription.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Committee Description is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Mandate Summary",
      "placeholder": "e.g Mandate Summary"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.mandateSummary.$touch();
      }
    },
    model: {
      value: _vm.form.mandateSummary,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mandateSummary", $$v);
      },
      expression: "form.mandateSummary"
    }
  }), _vm.$v.form.mandateSummary.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Mandate Summary is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-upload-field', {
    staticClass: "mb-4",
    attrs: {
      "loading": _vm.isUploading,
      "label": 'Mandate document',
      "required": true
    },
    on: {
      "input": function ($event) {
        return _vm.uploadFile($event);
      }
    }
  })], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "values": _vm.users,
      "label": 'Committee Members',
      "loading-values": _vm.isFetchingCommittee,
      "defaultValue": _vm.form.committeeMembers,
      "selected-label": 'Selected Committee Members(s)',
      "create-label": 'Create Committee Member'
    },
    on: {
      "create": function ($event) {
        return _vm.$bvModal.show('modal-create-committee-member');
      }
    },
    model: {
      value: _vm.form.committeeMembers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "committeeMembers", $$v);
      },
      expression: "form.committeeMembers"
    }
  })], 1), _c('b-col', [_c('create-user-modal', {
    attrs: {
      "modalId": "create-committee-member",
      "user-type": "Committee Member",
      "show-roles": false
    },
    on: {
      "completed": function ($event) {
        _vm.fetchUsers();
        _vm.$bvModal.hide('modal-create-committee-member');
      }
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "text": "Cancel",
      "variant": "light"
    },
    on: {
      "click": _vm.closeModal
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "loading": _vm.isSaving,
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "text": `${_vm.isEdit ? 'Update' : 'Save'}`,
      "variant": "warning"
    },
    on: {
      "click": _vm.createCommittee
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }