
import Vue from "vue";
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import Company from "@/app/components/tabs/view-report/Company.vue";
import Documentation from "@/app/components/tabs/view-report/Documentation.vue";
import Stakeholders from "@/app/components/tabs/view-report/Stakeholders.vue";
import Structure from "@/app/components/tabs/view-report/Structure.vue";
import Principles from "@/app/components/tabs/view-report/Principles.vue";
import Certification from "@/app/components/tabs/view-report/Certification.vue";
import QuestionOutput from "@/app/components/reports/QuestionOutput.vue";

export default Vue.extend({
    name: "PreviewReportModal",
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        Company,
        Documentation,
        Stakeholders,
        Structure,
        Principles,
        Certification,
        QuestionOutput,
    },
    data() {
        return {
            isDownloading: false,
        };
    },
    computed: {
        ...mapGetters({
            companyData: "company/getProfile",
        }),
    },
    methods: {
        sendFlaggedProps(value: { description: string; comment: string }) {
            this.$emit("clicked", value);
        },
        async exportPdf() {
            this.isDownloading = true;

            this.$bvToast.toast("The document is downloading, this might take a while. Remain on this tab while this happens and kindly try again if it exceeds 4 mins", {
                title: "Document downloading",
                variant: "success",
                solid: true,
            });

            const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'));
            const pdfOptions = {
                margin: 0.5,
                filename: `${this.report.reportInformation?.reportCode} - NCCG Report ${new Date().toISOString().slice(0, 19)}.pdf`,
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: {
                    dpi: 192,
                    letterRendering: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "letter",
                    orientation: "portrait",
                },
            };
            const downloadPDF = (elements: any, options: any) => {
                let worker = html2pdf().set(options).from(elements[0]);

                if (elements.length > 1) {
                    worker = worker.toPdf(); // worker is now a jsPDF instance

                    // add each element/page individually to the PDF render process
                    elements.slice(1).forEach((element: any, index: number) => {
                        worker = worker
                            .get("pdf")
                            .then((pdf: any) => {
                                pdf.addPage();
                            })
                            .from(element)
                            .toContainer()
                            .toCanvas()
                            .toPdf();
                    });
                }

                worker = worker.save().then(() => {
                    this.isDownloading = false;
                });
            };
            await downloadPDF(pages, pdfOptions);
        },
    },
});
