var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "h-100 relative overflow-hidden",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "3",
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "ba-sidebar bg-white w-100"
  }, [_c('div', {}, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._t("sidebar-image")], 2), _vm._t("sidebar-body")], 2)])]), _c('b-col', [_c('div', {
    staticClass: "h-100 py-5o verflow-auto"
  }, [_vm._t("body")], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }