
import Vue from "vue";
import { romanize } from "../../../utils/helpers";

enum IQuestionType {
    TEXT,
    LONGTEXT,
    NUMBER,
    DATEPICKER,
    MEDIA,
    DROPDOWN,
    MULTIPLE_DROPDOWN,
    LINKED_TYPE,
    BOOLEAN,
    BOOLEAN_AND_LONGTEXT,
    BOOLEAN_AND_LONGTEXT_AND_MEDIA,
    LONGTEXT_AND_MEDIA,
    TEXT_AND_DATEPICKER,
    DROPDOWN_AND_DATEPICKER,
    MULTIPLE_DROPDOWN_AND_LONGTEXT,
    BOOLEAN_AND_DROPDOWN_AND_LONGTEXT,
    DROPDOWN_AND_LONGTEXT,
}
export default Vue.extend({
    props: {
        question: {
            type: Object,
        },
        index: Number,
        usage: {
            type: String,
            required: true,
        },
    },
    data(): {
        IQuestionType: any;
        form: {
            attatchments: any[];
            booleanValue: boolean;
            dateValue: string | null;
            listValue: any[];
            numericValue: number;
            stringValue: string | null;
            dropdownValue: string | null;
            timeOfAction: string | null;
            uniqueName: string;
        };
    } {
        return {
            IQuestionType,
            form: {
                attatchments: this.question?.attatchments,
                booleanValue: this.question?.booleanValue,
                dateValue: this.question?.dateValue,
                listValue: this.question?.listValue,
                numericValue: Number(this.question?.numericValue) || 0,
                stringValue: this.question?.stringValue,
                dropdownValue: this.question?.dropdownValue ?? "",
                timeOfAction: this.question?.timeOfAction,
                uniqueName: this.question?.uniqueName,
            },
        };
    },

    methods: {
        romanize,
        async uploadFile(fileArray: any, index: number) {
            this.form.attatchments = [...fileArray];
        },
    },
    watch: {
        form: {
            handler(val) {
                this.$emit("input", { val, index: this.index });
            },
            deep: true,
        },
        question: {
            handler(val) {
                this.form = { ...val };
            },
            deep: true,
        },
    },
});
