var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading,
      "id": "BA_AMP_DETAILS_PAGE"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-dark-gray title-text"
  }, [_vm._v("Reporting Details")]), _c('div', {
    staticClass: "title-line"
  })]), _c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Title of Report",
      "readonly": "",
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "readonly": "",
      "disabled": "",
      "label": "Report ID",
      "placeholder": "-"
    },
    model: {
      value: _vm.form.reference,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reference", $$v);
      },
      expression: "form.reference"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-datepicker', {
    attrs: {
      "required": "",
      "max": _vm.minDate,
      "label": "Reporting Timeline (From)"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.reportingFrom.$touch();
      }
    },
    model: {
      value: _vm.form.reportingFrom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reportingFrom", $$v);
      },
      expression: "form.reportingFrom"
    }
  }), _vm.$v.form.reportingFrom.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Reporting Timeline(From) is required")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-datepicker', {
    attrs: {
      "required": "",
      "min": _vm.form.reportingFrom,
      "label": "Reporting Timeline(To)"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.reportingTo.$touch();
      }
    },
    model: {
      value: _vm.form.reportingTo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reportingTo", $$v);
      },
      expression: "form.reportingTo"
    }
  }), _vm.$v.form.reportingTo.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Reporting Timeline(TO) is required and should be a date after Reporting Timeline(FROM)")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-dropdown', {
    attrs: {
      "required": "",
      "initialValue": _vm.form.sectorialRegulator,
      "label": "Sectorial Regulator",
      "options": _vm.sectorialRegulatorOptions
    },
    on: {
      "hide": function ($event) {
        return _vm.$v.form.sectorialRegulator.$touch();
      }
    },
    model: {
      value: _vm.form.sectorialRegulator,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sectorialRegulator", $$v);
      },
      expression: "form.sectorialRegulator"
    }
  }), _vm.$v.form.sectorialRegulator.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Sectorial Regulator is required")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-dropdown', {
    attrs: {
      "initialValue": _vm.form.otherRegulators,
      "label": "Other Regulator",
      "options": _vm.otherRegulatorsOptions,
      "multiple": "",
      "show-selection": true
    },
    on: {
      "hide": function ($event) {
        return _vm.$v.form.otherRegulators.$touch();
      }
    },
    model: {
      value: _vm.form.otherRegulators,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherRegulators", $$v);
      },
      expression: "form.otherRegulators"
    }
  }), _vm.$v.form.otherRegulators.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Other Regulator is required")]) : _vm._e()], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mx-0 mt-5 button__wrapper"
  }, [_c('ba-button', {
    staticClass: "border bg-transparent border-black-10 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSavingDraft,
      "loading": _vm.isSavingDraft,
      "text": "Save and continue later"
    },
    on: {
      "click": _vm.saveDraft
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "id": "BA_AMP_CANCEL_DETAILS",
      "text": "Cancel",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), _c('ba-button', {
    staticClass: "text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Next",
      "id": "BA_AMP_NEXT_DETAILS",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.save(false);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }