<template>
    <section class="mt-body container-fluid-custom">
        <b-row class="pt-4">
            <b-col class="d-flex">
                <div class="mr-4 pointer" @click="$router.go(-1)">
                    <ba-base-icon name="ba-left-blue" class="mr-1" />
                    <span class="back-text">Back</span>
                </div>
                <div class="route-text">
                    <p class="route-text--dash mb-0">Dashboard</p>
                    <p class="route-text--dash--active mb-0">/ {{ !isReassigningSignatory ? "Create Report" : "Reassign Report Signatories" }}</p>
                </div>
            </b-col>
        </b-row>
        <div class="create-report__banner px-4 mb-5">
            <ba-base-icon name="create-report-banner-icon" />
            <div class="create-report__text">
                <h1 class="create-report__heading">{{ !isReassigningSignatory ? "Create Report" : "Reassign Report Signatories" }}</h1>
                <p v-if="!isReassigningSignatory" class="create-report__description">Complete the forms to create a report for the reporting period</p>
            </div>
        </div>
        <sidebar-layout>
            <template #sidebar-body>
                <div class="w-100 px-3">
                    <ba-vertical-tabs :tabs="filteredTabs" :currentTab="tabIndex" @changeTab="setTab" />
                </div>
            </template>
            <template #body>
                <div>
                    <keep-alive>
                        <component
                            v-if="currentTab.component"
                            :is="currentTab.component"
                            @completed="next"
                            @refresh="fetchReportsData"
                            v-bind="{ report, isLoading, isReassigningSignatory, haveUnresolvedComments }"
                            @back="
                                onBack({
                                    name: indexRouteName,
                                    query: { tab: 'report' },
                                })
                            " />
                    </keep-alive>
                </div>
            </template>
        </sidebar-layout>

        <KeepAlive>
            <Draggable v-if="!isLoading && this.report.comments && report.comments.length > 0" :position="{ right: '24px', bottom: '100px' }">
                <ReportComments :report="report" @set-unresolved-comments="haveUnresolvedComments = $event" />
            </Draggable>
        </KeepAlive>
    </section>
</template>

<script>
import Vue from "vue";
import createForm from "@/app/mixins/create-form";
import { mapState } from "vuex";
import storePrinciples from "@/assets/providers/principles";
import { DateTime } from "luxon";
import ReportComments from "@/app/components/reports/ReportComments.vue";
import Draggable from "@/app/components/Draggable.vue";
import SidebarLayout from "../../layouts/SidebarLayout.vue";
import ReportDetails from "../../components/sections/create-report/Details.vue";
import Stakeholders from "../../components/sections/create-report/Stakeholders.vue";
import Structures from "../../components/sections/create-report/Structures.vue";
import Documentation from "../../components/sections/create-report/Documentation.vue";
import Principles from "../../components/sections/create-report/Principles.vue";
import Signatories from "../../components/sections/create-report/Signatories.vue";
import { HOME } from "../../routes/endpoints";
import ReportService from "../../../api/report-service";

export default Vue.extend({
    mixins: [createForm],
    props: {
        isReassigningSignatory: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        SidebarLayout,
        ReportDetails,
        Stakeholders,
        Structures,
        Documentation,
        Principles,
        Signatories,
        ReportComments,
        Draggable,
    },
    data() {
        return {
            routeName: !this.isReassigningSignatory ? HOME.CREATE_REPORT.NAME : HOME.REASSIGN_REPORT_SIGNATORIES.NAME,
            indexRouteName: HOME.INDEX.NAME,
            report: {},
            extReport: {},
            isLoading: false,
            IsReportExist: false,
            tabIndex: 0,
            tabs: [
                { title: "Details", component: "report-details", isComplete: false },
                { title: "Governance Stakeholders", component: "stakeholders", isComplete: false },
                { title: "Governance Structures", component: "structures", isComplete: false },
                { title: "Documentation", component: "documentation", isComplete: false },
                { title: "Principles", component: "principles", isComplete: false },
                { title: "Signatories", component: "signatories", isComplete: false },
            ],
            attachments: [
                { label: "Attendance Register", key: "ATTENDANCE_REGISTER", normalizedName: "D2" },
                { label: "Primary Operating License", key: "PRIMARY_OPERATING_LICENSE", normalizedName: "D9" },
                { label: "Other documents", key: "OTHERS", normalizedName: "D4" },
            ],
            haveUnresolvedComments: false,
        };
    },
    computed: {
        ...mapState("reports", ["existingReports", "isReportDetailsSaved"]),
        currentTab() {
            return this.filteredTabs[this.tabIndex];
        },
        documentsArray() {
            const array = [];
            // eslint-disable-next-line no-unused-expressions, array-callback-return
            return this.report?.documents.map((docs) => {
                return this.attachments.forEach((att) => {
                    if (docs.name === att.label) {
                        console.log(docs);
                        array.push({
                            ...docs,
                            name: att.normalizedName,
                        });
                    }
                    return array;
                });
            });
        },
        filteredTabs() {
            let res = [
                { title: "1. Details", component: "report-details", isComplete: false },
                { title: "2. Governance Structures", component: "structures", isComplete: false },
                { title: "3. Governance Stakeholders", component: "stakeholders", isComplete: false },
                { title: "4. Documentation", component: "documentation", isComplete: false },
                { title: "5. Principles", component: "principles", isComplete: false },
                { title: "6. Reviewer & Signatories", component: "signatories", isComplete: false },
            ];

            if (this.isReassigningSignatory) {
                res = [res[res.length - 1]];
                console.log(res);
            }

            return res;
        },
        allPrinciples() {
            return storePrinciples.map((principle) => {
                return {
                    ...principle,
                    questions: principle.questions.map((question) => {
                        return {
                            ...question,
                            type: [question.dataType],
                            isUploading: false,
                            attachments: this.getReportPrinciple(question.uniqueName)?.attatchments || [],
                            ...this.getReportPrinciple(question.uniqueName),
                            dateValue: DateTime.fromISO(this.getReportPrinciple(question.uniqueName)?.dateValue).toISODate() || "",
                        };
                    }),
                };
            });
        },
    },
    methods: {
        async fetchReportByRouteId() {
            const { id } = this.$route.query;
            this.isLoading = true;
            try {
                const res = await ReportService.fetchReportDetails(id);
                this.report = res.data;
                this.haveUnresolvedComments = res.data.comments.some((comment) => !comment.resolved);
                // this.savePrinciples()
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async fetchReportWithExisting() {
            const { id } = this.$route.query;
            this.isLoading = true;
            try {
                const res = await ReportService.fetchReportDetails(id);
                const payload = {
                    ...this.extReport,
                    id: res.data?.id,
                    details: {
                        ...res.data?.details,
                        otherRegulator: this.extReport?.details?.otherRegulator,
                        sectorialRegulator: this.extReport?.details?.sectorialRegulator,
                    },
                };
                this.report = payload;
                if (this.report) {
                    await this.saveDetails();
                }
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        fetchReportsData() {
            if (this.existingReports === "") {
                this.fetchReportByRouteId();
            } else {
                this.fetchReportWithExisting();
            }
        },
        async fecthExistingReport() {
            const exId = this.existingReports?.id;
            try {
                const res = await ReportService.fetchReportDetails(exId);
                this.extReport = res.data;
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async saveDetails() {
            const { id } = this.$route.query;
            const structure = {
                commitee: this.report?.structure?.commitee,
                positionUsers: this.report?.structure?.positionUsers.map((users) => {
                    return {
                        positionName: users.positionName,
                        userIds: users.userIds,
                    };
                }),
            };
            const stakeholders = {
                auditorIds: this.report?.profile?.auditorIds,
                boardConsultantIds: this.report?.profile?.boardConsultantIds,
                governanceConsultantIds: this.report?.profile?.governanceConsultantIds,
                isRegistrarSecretary: this.report?.profile?.isRegistrarSecretary,
                registrarList: this.report?.profile?.registrarList,
            };
            const signatories = this.report.signatories.map((users) => {
                return {
                    position: users.normalizedName,
                    userId: users.userId,
                };
            });
            const reviewerId = this.report?.details?.reviewerId;

            const matchedAttachments = this.report?.documents
                .map((doc) => {
                    const attachment = this.attachments.find((att) => att.label === doc.name);
                    return attachment ? { name: attachment.normalizedName, attatchments: doc.attatchments } : null;
                })
                .filter((att) => att !== null);
            this.isLoading = true;
            try {
                await this.savePrinciples();
                await ReportService.updateReportDetails({ id, body: this.report?.details });
                await ReportService.updateStructure({ id, body: structure });
                await ReportService.updateStakeholderDetails({ id, body: stakeholders });
                await ReportService.updateReportDocuments({ id, body: matchedAttachments });
                await ReportService.updateSignatories({ id, reviewerId, body: signatories });
                this.$store.commit("reports/SET_EXISTING_REPORT", "");
                await this.fetchReportsData();
                this.$bvToast.toast("Report Updated Successfully", { title: "Success!", variant: "success", solid: true });
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
        async savePrinciples() {
            const { id } = this.$route.query;
            const principle = this.allPrinciples.map((p) => {
                return p.questions;
            });
            try {
                principle.forEach(async (input, index) => {
                    await ReportService.updatePrinciple({
                        reportId: id,
                        principleName: `PRINCIPLE${index + 1}`,
                        body: input.map((answer) => {
                            return {
                                inputIdentifier: answer.uniqueName,
                                stringValue: answer.stringValue,
                                numericValue: Number(answer.numericValue) || 0,
                                booleanValue: answer.booleanValue,
                                dateValue: answer.dateValue || null,
                                listValue: answer.listValue,
                                attatchments: answer.attatchments,
                            };
                        }),
                    });
                });
            } catch (error) {
                console.log(error);
            }
        },
        getReportPrinciple(questionId) {
            return this.report?.principleInputs?.find((input) => input.inputIdentifier === questionId);
        },
    },
    async mounted() {
        const exId = this.existingReports?.id;
        if (exId) {
            await this.fecthExistingReport();
        }
        const { id } = this.$route.query;
        if (id) {
            await this.fetchReportsData();
        }
        // this.haveUnresolvedComments = this.report.comments.some((comment) => !comment.resolved)
    },
});
</script>
<style scoped lang="scss">
.back-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #3e4e6a;
}
.route-text {
    display: flex;
    align-items: center;
    gap: 10px;
    &--dash {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #919090;
        &--active {
            color: #806500;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }
}
.create-report {
    &__banner {
        background: #f6f6f6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 100px;
        margin-top: 26px;
    }
    &__text {
        padding: 30px;
    }

    &__heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #232123;
    }
    &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #919090;
        margin-bottom: 0;
    }
}
</style>
