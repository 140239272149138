
import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import ReportService from "../../../api/report-service";

export default Vue.extend({
    name: "CreateConsultantModal",
    props: {
        type: {
            type: String,
            default: "Consultant",
        },
        modalId: {
            type: String,
            default: "create-consultant",
        },
    },
    data() {
        return {
            isSaving: false,
            isEdit: false,
            isFetchingConsultant: false,
            form: {
                id: "",
                fullName: "",
                primaryAddress: "",
                profile: "",
                email: "",
                phone: "",
                dateOfAppointment: "",
                consultantYearFee: [
                    {
                        year: new Date().getFullYear(),
                        fee: 0,
                    },
                ],
            },
        };
    },
    methods: {
        async createConsultant() {
            this.isSaving = true;

            const body = {
                name: this.form.fullName,
                email: this.form.email,
                phoneNumber: this.form.phone,
                primaryAddress: this.form.primaryAddress,
                dateOfAppointment: this.form.dateOfAppointment,
            };
            try {
                if (!this.isEdit) {
                    const res = await ReportService.createConsultant(body);

                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                } else {
                    const res = await ReportService.updateConsultant({
                        id: this.form.id,
                        body,
                    });
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                }

                this.$emit("completed");
                this.resetData();
            } catch (error) {
                this.isSaving = false;
            } finally {
                this.isSaving = false;
                // Object.assign(this.$parent.$data, this.$parent.$options.data());
            }
        },
        closeModal () {
            this.resetData()
            this.$bvModal.hide(`modal-${this.modalId}`);
        },
        resetData() {
            this.isEdit = false;
            this.form = {
                id: "",
                fullName: "",
                primaryAddress: "",
                profile: "",
                email: "",
                phone: "",
                dateOfAppointment: "",
                consultantYearFee: [
                    {
                        year: new Date().getFullYear(),
                        fee: 0,
                    },
                ],
            };
        },
        addConsultantFee() {
            this.form.consultantYearFee.push({
                year: 0,
                fee: 0,
            });
        },
        removeConsultantYear(index: number) {
            this.form.consultantYearFee.splice(index, 1);
        },
        async fetchConsultant(id: string) {
            //
            console.log(this.isEdit);
            console.log("consultant", id);
            this.isEdit = true;
            try {
                this.isFetchingConsultant = true;
                const { data } = await ReportService.fetchConsultantById(id);

                this.setForm(data);
            } catch (error) {
                this.isFetchingConsultant = false;
            } finally {
                this.isFetchingConsultant = false;
            }
        },
        setForm(data: any) {
            this.form.id = data.id;
            this.form.fullName = data.name;
            this.form.primaryAddress = data.primaryAddress;
            this.form.email = data.email;
            this.form.phone = data.phoneNumber;
            this.form.dateOfAppointment = dayjs(data.dateOfAppointment).format('YYYY-MM-DD');
        },
    },
    validations(): any {
        return {
            form: {
                fullName: {
                    required,
                },
                primaryAddress: {
                    required,
                },
                profile: {},
                dateOfAppointment: {
                    required,
                },
                email: {
                    required,
                },
                phone: {
                    required,
                },
            },
        };
    },
});
