var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3 principle-input",
    staticStyle: {
      "border-bottom": "1px solid #e9e9e9",
      "padding-bottom": "10px"
    }
  }, [_c('b-button', {
    staticClass: "text-left p-0 m-0",
    attrs: {
      "variant": "transparent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$root.$emit('bv::toggle::collapse', `principle-${_vm.index}`);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "principle-title"
  }, [_vm._v(" " + _vm._s(_vm.principle.name)), _vm.principle.name ? _c('span', [_vm._v(_vm._s(_vm.usage === "principles" ? ":" : "."))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.principle.title) + " ")]), _c('ba-base-icon', {
    attrs: {
      "name": "ba-chev-down",
      "width": "20px",
      "height": "20px"
    }
  })], 1)]), _c('b-collapse', {
    attrs: {
      "id": `principle-${_vm.index}`
    }
  }, [_c('div', [_c('div', {
    staticClass: "principle-description my-3"
  }, [_vm._v(_vm._s(_vm.principle.description))]), _vm._l(_vm.principle.questions, function (question, ix) {
    return _c('div', {
      key: ix
    }, [question.dataType === 4 ? _c('div', [_vm._l(_vm.otherDocumentations, function (documentation, index) {
      return _c('ba-upload-field', {
        key: index,
        attrs: {
          "label": "Untitled document",
          "id": documentation.id,
          "description": "Upload files"
        },
        on: {
          "input": function ($event) {
            return _vm.uploadFile($event, index);
          }
        }
      });
    }), _c('button', {
      staticClass: "add-document bg-transparent w-100 d-flex align-items-center justify-content-center m-0 text-black-90"
    }, [_c('BaBaseIcon', {
      staticClass: "text-black-100",
      attrs: {
        "name": "plus-icon-blue"
      }
    }), _vm._v(" Add Document ")], 1)], 2) : _c('div', [_c('QuestionInput', {
      key: ix,
      attrs: {
        "principle": _vm.principle,
        "question": question,
        "index": ix,
        "usage": "others"
      },
      on: {
        "input": function ($event) {
          return _vm.handleQuestionInput($event);
        }
      },
      model: {
        value: _vm.form.answers[ix],
        callback: function ($$v) {
          _vm.$set(_vm.form.answers, ix, $$v);
        },
        expression: "form.answers[ix]"
      }
    })], 1)]);
  }), _vm.principle.name && _vm.principle.name !== 'Y' || _vm.principle.title !== 'Other documentation' ? _c('div', {
    staticClass: "my-3"
  }, [_c('BaButton', {
    staticClass: "text-white bg-green-50 border-0",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving || _vm.usage === 'others',
      "loading": _vm.isSaving,
      "text": _vm.submitText
    },
    on: {
      "click": function ($event) {
        return _vm.save(_vm.index);
      }
    }
  })], 1) : _vm._e()], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }