
import Vue from "vue";
import { DateTime } from "luxon";
import principles from "@/assets/providers/principles";
import PrincipleInput from "../../reports/PrincipleInput.vue";

enum IQuestionType {
    TEXT,
    LONGTEXT,
    NUMBER,
    DATEPICKER,
    MEDIA,
    DROPDOWN,
    MULTIPLE_DROPDOWN,
    LINKED_TYPE,
    BOOLEAN,
    BOOLEAN_AND_LONGTEXT,
    BOOLEAN_AND_LONGTEXT_AND_MEDIA,
    LONGTEXT_AND_MEDIA,
    TEXT_AND_DATEPICKER,
    DROPDOWN_AND_DATEPICKER,
    MULTIPLE_DROPDOWN_AND_LONGTEXT,
}

export default Vue.extend({
    components: {
        PrincipleInput,
    },
    props: {
        report: Object,
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isSaving: false,
            isSavingDraft: false,
            form: {
                title: "",
                reference: "",
                reportingFrom: "",
                reportingTo: "",
                sectorialRegulator: "",
                otherRegulator: "",
                attachments: [
                    {
                        label: "Proof of business License",
                        key: "BUSINESS_LICENSE",
                        multiple: true,
                        isUploading: false,
                        files: [],
                    },
                    {
                        label: "Attendance Register",
                        key: "ATTENDANCE_REGISTER",
                        multiple: true,
                        isUploading: false,
                        files: [],
                    },
                    {
                        label: "Memorandum & Articles of Association",
                        key: "MEMORANDUM_OF_ASSOCIATION",
                        multiple: false,
                        isUploading: false,
                        files: [],
                    },
                    {
                        label: "Certificate of Incorporation",
                        key: "CERTIFICATE_OF_INCORPORATION",
                        multiple: false,
                        isUploading: false,
                        files: [],
                    },
                    {
                        label: "Minute Book",
                        key: "MINUTE_BOOK",
                        multiple: true,
                        isUploading: false,
                        files: [],
                    },
                    {
                        label: "Proof of registered address",
                        key: "PROOF_OF_BUSINESS",
                        multiple: true,
                        isUploading: false,
                        files: [],
                    },
                    {
                        label: "Other documents",
                        key: "OTHERS",
                        multiple: true,
                        isUploading: false,
                        files: [],
                    },
                ],
            },
            sectorialRegulatorOptions: [],
            otherRegulatorOptions: [],
            principles: [
                {
                    label: "A successful Company is headed by an effective Board which is responsible for providing entrepreneurial and strategic leadership as well as promoting ethical culture and responsible corporate citizenship. As a link between stakeholders and the Company, the Board is to exercise oversight and control to ensure that management acts in the best interest of the shareholders and other stakeholders while sustaining the prosperity of the Company",
                    questions: [
                        {
                            type: [IQuestionType.BOOLEAN],
                            label: "Does the Board have an approved Charter which sets out its responsibilities and terms of reference?",
                        },
                    ],
                    required: true,
                },
            ],
            // allPrinciples: principles,
            scTimer: 0 as any,
            scY: 0 as any,
            footerFixed: false as boolean,
        };
    },
    computed: {
        allPrinciples() {
            return principles.map((principle: any) => {
                return {
                    ...principle,
                    questions: principle.questions.map((question: any) => {
                        return {
                            ...question,
                            type: [question.dataType],
                            isUploading: false,
                            attachments: this.getReportPrinciple(question.uniqueName)?.attatchments || [],
                            ...this.getReportPrinciple(question.uniqueName),
                            dateValue: DateTime.fromISO(this.getReportPrinciple(question.uniqueName)?.dateValue).toISODate() || "",
                        };
                    }),
                };
            });
        },
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll(e: any) {
            if (this.scTimer) {
                return;
            }
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY;
                clearTimeout(this.scTimer);
                this.scTimer = 0;
            }, 100);
            const value = e.target.documentElement.scrollTop;
            if (value > 500) {
                this.footerFixed = true;
            } else {
                this.footerFixed = false;
            }
        },
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        async save() {
            this.$emit("completed");
            // if (isLater) this.isSavingDraft = true;
            // else this.isSaving = true;
        },
        getReportPrinciple(questionId: string) {
            return this.report?.principleInputs?.find((input: any) => input.inputIdentifier === questionId);
        },
        async saveDraft() {
            this.save();
        },
        cancel() {
            this.$emit("back");
        },
    },

    validations: {
        form: {
            title: {},
            reference: {},
            reportingFrom: {},
            reportingTo: {},
            sectorialRegulator: {},
            otherRegulator: {},
        },
    },
});
