var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "mt-body container-fluid-custom"
  }, [_c('b-row', {
    staticClass: "pt-4"
  }, [_c('b-col', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-4 pointer",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('ba-base-icon', {
    staticClass: "mr-1",
    attrs: {
      "name": "ba-left-blue"
    }
  }), _c('span', {
    staticClass: "back-text"
  }, [_vm._v("Back")])], 1), _c('div', {
    staticClass: "route-text"
  }, [_c('p', {
    staticClass: "route-text--dash mb-0"
  }, [_vm._v("Dashboard")]), _c('p', {
    staticClass: "route-text--dash--active mb-0"
  }, [_vm._v("/ " + _vm._s(!_vm.isReassigningSignatory ? "Create Report" : "Reassign Report Signatories"))])])])], 1), _c('div', {
    staticClass: "create-report__banner px-4 mb-5"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "create-report-banner-icon"
    }
  }), _c('div', {
    staticClass: "create-report__text"
  }, [_c('h1', {
    staticClass: "create-report__heading"
  }, [_vm._v(_vm._s(!_vm.isReassigningSignatory ? "Create Report" : "Reassign Report Signatories"))]), !_vm.isReassigningSignatory ? _c('p', {
    staticClass: "create-report__description"
  }, [_vm._v("Complete the forms to create a report for the reporting period")]) : _vm._e()])], 1), _c('sidebar-layout', {
    scopedSlots: _vm._u([{
      key: "sidebar-body",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100 px-3"
        }, [_c('ba-vertical-tabs', {
          attrs: {
            "tabs": _vm.filteredTabs,
            "currentTab": _vm.tabIndex
          },
          on: {
            "changeTab": _vm.setTab
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function () {
        return [_c('div', [_c('keep-alive', [_vm.currentTab.component ? _c(_vm.currentTab.component, _vm._b({
          tag: "component",
          on: {
            "completed": _vm.next,
            "refresh": _vm.fetchReportsData,
            "back": function ($event) {
              return _vm.onBack({
                name: _vm.indexRouteName,
                query: {
                  tab: 'report'
                }
              });
            }
          }
        }, 'component', {
          report: _vm.report,
          isLoading: _vm.isLoading,
          isReassigningSignatory: _vm.isReassigningSignatory,
          haveUnresolvedComments: _vm.haveUnresolvedComments
        }, false)) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('KeepAlive', [!_vm.isLoading && this.report.comments && _vm.report.comments.length > 0 ? _c('Draggable', {
    attrs: {
      "position": {
        right: '24px',
        bottom: '100px'
      }
    }
  }, [_c('ReportComments', {
    attrs: {
      "report": _vm.report
    },
    on: {
      "set-unresolved-comments": function ($event) {
        _vm.haveUnresolvedComments = $event;
      }
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }