
import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import ReportService from "../../../api/report-service";

export default Vue.extend({
    name: "CreateAuditorModal",
    props: {},
    data() {
        return {
            isSaving: false,
            isFetchingAuditor: false,
            isEdit: false,
            form: {
                id: "",
                fullName: "",
                primaryAddress: "",
                profile: "",
                email: "",
                phone: "",
                dateOfAppointment: "",
                auditYearFee: [
                    {
                        year: new Date().getFullYear(),
                        fee: 0,
                    },
                ],
            },
        };
    },
    methods: {
        async createAuditor() {
            this.isSaving = true;

            const body = {
                name: this.form.fullName,
                // email: this.form.email,
                // phoneNumber: this.form.phone,
                // primaryAddress: this.form.primaryAddress,
                dateOfAppointment: this.form.dateOfAppointment,
            };
            try {
                if (!this.isEdit) {
                    const res = await ReportService.createAuditor(body);

                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                } else {
                    const res = await ReportService.updateAuditor({
                        id: this.form.id,
                        body,
                    });
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                }

                this.$emit("completed");
                this.resetData();
            } catch (error) {
                this.isSaving = false;
            } finally {
                this.isSaving = false;
            }
        },
        addAuditFee() {
            this.form.auditYearFee.push({
                year: 0,
                fee: 0,
            });
        },
        removeAuditYear(index: number) {
            this.form.auditYearFee.splice(index, 1);
        },
        closeModal () {
            this.resetData()
            this.$bvModal.hide("modal-create-auditor");
        },
        resetData() {
            this.isEdit = false;
            this.form = {
                id: "",
                fullName: "",
                primaryAddress: "",
                profile: "",
                email: "",
                phone: "",
                dateOfAppointment: "",
                auditYearFee: [
                    {
                        year: new Date().getFullYear(),
                        fee: 0,
                    },
                ],
            };
        },
        async fetchAuditor(id: string) {
            //
            console.log(this.isEdit);
            console.log("auditor", id);
            this.isEdit = true;
            try {
                this.isFetchingAuditor = true;
                const { data } = await ReportService.fetchAuditorById(id);

                this.setForm(data);
            } catch (error) {
                this.isFetchingAuditor = false;
            } finally {
                this.isFetchingAuditor = false;
            }
        },
        setForm(data: any) {
            this.form.id = data.id;
            this.form.fullName = data.name;
            this.form.dateOfAppointment = dayjs(data.dateOfAppointment).format('YYYY-MM-DD');
        },
    },
    validations(): any {
        return {
            form: {
                fullName: {
                    required,
                },
                primaryAddress: {
                    // required,
                },
                profile: {
                    // required,
                },
                dateOfAppointment: {
                    required,
                },
                email: {
                    // required,
                },
                phone: {
                    // required,
                },
            },
        };
    },
});
