import Vue from "vue";

type Itab = {
    title: string;
    component: string;
    isComplete: boolean;
};

export default Vue.extend({
    data(): {
        tabIndex: number;
        tabs: Itab[];
        routeName: string;
    } {
        return {
            tabIndex: 0,
            tabs: [],
            routeName: "",
        };
    },
    computed: {
        currentTab(): Itab {
            return this.tabs[this.tabIndex];
        },
    },
    methods: {
        next() {
            if (this.tabIndex < this.tabs.length - 1) {
                this.tabs[this.tabIndex].isComplete = true;
                this.setTab(this.tabIndex + 1, true);
            }
        },
        onBack({ name, query }: { name: string; query: { tab: string } }) {
            if (this.tabIndex > 0) {
                this.setTab(this.tabIndex - 1);
            } else {
                this.$router.push({ name, query });
            }
        },
        setTab(tabIndex: number, hasCompletedPrevious = false): void {
            const prevTab = this.tabs[this.tabIndex];

            this.tabIndex = tabIndex;

            const tab: Itab = this.tabs[this.tabIndex];

            this.$router.replace({
                name: this.routeName,
                query: {
                    ...this.$route.query,
                    tab: tab.component,
                    [`${prevTab.component}`]: `${hasCompletedPrevious}`,
                },
            });
        },
        setTabStatuses() {
            this.tabs.forEach((tab, ix) => {
                const { component } = tab;

                const hasCompleted = this.$route.query[component];
                const status = hasCompleted === "true";
                this.tabs[ix].isComplete = status;
            });
        },
    },
    mounted() {
        const { tab } = this.$route.query;
        this.setTabStatuses();

        // console.log(tab);
        if (tab) {
            const tabIndex = this.tabs?.findIndex(
                (_tab: Itab) => _tab.component === tab,
            );

            const tabStatus: boolean = this.tabs[tabIndex]
                .isComplete as boolean;

            if (tabIndex > -1) {
                this.setTab(tabIndex, tabStatus);
            }
        } else {
            this.setTab(0);
        }
    },
});
